import React, { useCallback, useState } from 'react'

import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'
import GooglePayButton from '@google-pay/button-react'
import { Box } from '@gousto-internal/citrus-react'
import * as braintree from 'braintree-web'
import { useDispatch, useSelector } from 'react-redux'

import { isProd } from '@library/environment/isomorphic'

import { actionTypes } from 'actions/actionTypes'
import statusActions from 'actions/status'
import {
  checkoutSignup,
  fireCheckoutError,
  saveGooglePay3dsTransactionComplete,
  saveGooglePayDeliveryAddress,
  saveGooglePayPersonalInfo,
  saveGooglePayToken,
  setCurrentPaymentMethod,
  trackCanPayWithGooglePay,
  trackClickWithGooglePay,
} from 'routes/Checkout/checkoutActions'
import { hasCheckoutError } from 'routes/Checkout/checkoutSelectors'
import { PaymentMethod } from 'routes/Signup/signupConfig'
import { getBasketPostcode } from 'selectors/basket'

import { Spinner } from './Spinner'
import { PRODUCTION, TEST } from './constants'
import { useGooglePayRequest } from './useGooglePayRequest'
import { useThreeDSecure } from './useThreeDSecure'
import { parseGooglePayNonce, validateShipping } from './utils'

export function GooglePay({
  googlePayInstance,
  setIsReadyToPay,
  threeDSecureInstance,
}: {
  googlePayInstance: braintree.GooglePayment | null
  setIsReadyToPay: (result: boolean) => void
  threeDSecureInstance: braintree.ThreeDSecure | null
}) {
  const { loading, paymentRequest, pricing } = useGooglePayRequest(googlePayInstance)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { verifyUserCard } = useThreeDSecure(threeDSecureInstance, setIsSubmitting)
  const dispatch = useDispatch()
  const basketPostcode = useSelector(getBasketPostcode)
  const ENVIRONMENT: google.payments.api.Environment = isProd() ? PRODUCTION : TEST
  const hasError = useSelector(hasCheckoutError)

  const handleSignupUser = useCallback(
    async (nonce: string, paymentData: google.payments.api.PaymentData) => {
      datadogRum.addAction('google_pay_session_started')
      dispatch(saveGooglePayDeliveryAddress(paymentData.shippingAddress))
      dispatch(saveGooglePayPersonalInfo(paymentData.shippingAddress))
      dispatch(saveGooglePayToken(nonce))
      await dispatch(checkoutSignup({ pricing }))
      if (!hasError) {
        setIsSubmitting(false)
      } else {
        throw new Error('GooglePay Signup failed')
      }
    },
    [dispatch, hasError, pricing],
  )

  const authenticateUser = useCallback(
    async (
      result: braintree.GooglePaymentTokenizePayload,
      paymentData: google.payments.api.PaymentData,
    ) => {
      const { isNetworkTokenized } = result.details

      datadogRum.addAction(`google_pay_is_network_tokenised_${isNetworkTokenized}`)

      if (isNetworkTokenized) {
        await handleSignupUser(result.nonce, paymentData)
      } else {
        const {
          nonce,
          details: { bin },
        } = result
        const { locality, postalCode, address1: streetAddress } = paymentData.shippingAddress || {}

        dispatch(saveGooglePay3dsTransactionComplete(true))
        const verifyCardResponse = await verifyUserCard({
          nonce,
          bin,
          billingAddress: {
            locality: locality as string,
            postalCode: postalCode as string,
            streetAddress: streetAddress as string,
          },
        })

        if (verifyCardResponse.success) {
          await handleSignupUser(verifyCardResponse.nonce, paymentData)
        }
      }
    },
    [dispatch, handleSignupUser, verifyUserCard],
  )

  const handlePaymentAuthorized = useCallback(
    async (paymentData: google.payments.api.PaymentData) => {
      if (!googlePayInstance) {
        datadogLogs.logger.error('handlePaymentAuthorized - google pay instance is null')
        setIsSubmitting(false)

        return
      }

      setIsSubmitting(true)
      trackClickWithGooglePay()
      datadogRum.addAction('click_pay_with_google_pay')
      dispatch(setCurrentPaymentMethod(PaymentMethod.GooglePay))
      dispatch({ type: actionTypes.GOOGLE_PAY_ERRORS_CLEAR })
      dispatch(statusActions.error(actionTypes.CHECKOUT_SIGNUP, null))
      dispatch(statusActions.error(actionTypes.USER_SUBSCRIBE, null))

      const result = await parseGooglePayNonce({ googlePayInstance, paymentData })
      try {
        if (!result) {
          dispatch(fireCheckoutError(actionTypes.GOOGLE_PAY_TOKEN_PARSING_ERROR))
          setIsSubmitting(false)
        }
        await authenticateUser(result as braintree.GooglePaymentTokenizePayload, paymentData)
      } catch (error: unknown) {
        /**
         * Any errors thrown by signup process will be captured by
         * <ExpressCheckout /> wrapper, returning transaction state
         * with ERROR will close the payment sheet and show error
         */
        datadogLogs.logger.error(
          'Error signing up GooglePay user',
          undefined,
          error instanceof Error ? error : new Error(String(error)),
        )
        setIsSubmitting(false)
      }
    },
    [googlePayInstance, dispatch, authenticateUser],
  )

  const handlePaymentDataChanged = useCallback(
    (data: google.payments.api.IntermediatePaymentData) =>
      validateShipping({ intermediatePaymentData: data, postCode: basketPostcode }),
    [basketPostcode],
  )

  const handleReadyToPayChange = useCallback(
    (state: { isReadyToPay: boolean }) => {
      setIsReadyToPay(state.isReadyToPay)
      if (state.isReadyToPay) trackCanPayWithGooglePay()
      datadogRum.addAction(`can_pay_with_google_pay_${state.isReadyToPay}`)
    },
    [setIsReadyToPay],
  )

  if (loading || !paymentRequest) {
    return null
  }

  return (
    <Box data-testid="expressCheckoutGooglePay" width="100%">
      {isSubmitting ? (
        <Spinner />
      ) : (
        <GooglePayButton
          environment={ENVIRONMENT}
          paymentRequest={paymentRequest}
          buttonSizeMode="fill"
          buttonColor="black"
          buttonType="pay"
          buttonRadius={4}
          style={{ width: '100%', height: '48px' }}
          onLoadPaymentData={handlePaymentAuthorized}
          onPaymentDataChanged={handlePaymentDataChanged}
          onReadyToPayChange={handleReadyToPayChange}
        />
      )}
    </Box>
  )
}
