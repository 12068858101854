import React, { useCallback, useEffect } from 'react'

import { datadogLogs } from '@datadog/browser-logs'
import {
  Body3,
  Box,
  Button,
  Color,
  Display,
  FlexDirection,
  FontWeight,
  Heading4,
  Heading5,
  Join,
  Space,
  Text,
} from '@gousto-internal/citrus-react'
import moment from 'moment'
import { browserHistory } from 'react-router'

import { Carousel } from './Carousel/Carousel'
import { RecipeImageTiles } from './RecipeImageTiles'

export type Order = {
  id: string
  deliveryDate: string
  number: string
  box: {
    numPortions: string
  }
  prices: {
    isDeliveryFree: boolean
    percentageOff: string
  }
}

type Props = {
  orders: Record<string, Order>
  userLoadOrders: () => void
}

const getFormattedDeliveryDate = (deliveryDate: string) =>
  moment(deliveryDate, 'YYYY-MM-DD').format('dddd, MMMM D')

const getSecondOrder = (orders: Record<string, Order>) => {
  const orderIds = Object.keys(orders)

  if (orderIds.length > 1) {
    for (let i = 0; i < orderIds.length; i += 1) {
      const order = orders[orderIds[i]]
      if (order.number === '2') {
        return order
      }
    }
  }

  return null
}

let ORDERS_FETCH_CURRENT_ATTEMPTS = 0
export const ORDERS_FETCH_MAX_ATTEMPTS = 6
export const WAIT_TIME = 2000

export const BuildYourNextBox = ({ orders, userLoadOrders }: Props) => {
  const ordersCount = Object.keys(orders).length
  const order = getSecondOrder(orders)

  const tryFetchOrders = useCallback(() => {
    const intervalId = setInterval(() => {
      if (ordersCount > 1) {
        clearInterval(intervalId)

        return
      }

      if (ORDERS_FETCH_CURRENT_ATTEMPTS <= ORDERS_FETCH_MAX_ATTEMPTS) {
        userLoadOrders()
        ORDERS_FETCH_CURRENT_ATTEMPTS += 1
      } else {
        clearInterval(intervalId)

        datadogLogs.logger.error(
          `Exceeded time to load orders after signing up. Order IDs: ${Object.keys(orders)}`,
        )
      }
    }, WAIT_TIME)

    return intervalId
  }, [orders, ordersCount, userLoadOrders])

  useEffect(() => {
    // sign up order only, then tries loading again until it finds a second order
    const intervalId = tryFetchOrders()

    return () => {
      clearInterval(intervalId)
    }
  }, [tryFetchOrders])

  const handleChooseRecipes = useCallback(() => {
    browserHistory.push(`/menu/${order?.id}`)
  }, [order])

  if (!order) {
    return null
  }

  const percentageOff = parseInt(order.prices.percentageOff, 10)
  const deliveryDate = getFormattedDeliveryDate(order.deliveryDate)
  const hasDiscount = percentageOff > 0

  const { isDeliveryFree } = order.prices
  const { numPortions } = order.box

  const renderPill = (labelText: string) => (
    <Box
      borderRadius={1.5}
      display={Display.InlineBlock}
      bg={Color.Success_800}
      paddingH={1}
      paddingV={0.5}
    >
      <Body3 fontWeight={FontWeight.Bold} color={Color.White}>
        {labelText}
      </Body3>
    </Box>
  )

  return (
    <Box>
      <Join with={<Space direction="vertical" size={2} />}>
        <Join with={<Space direction="vertical" size={0.5} />}>
          <Heading4 color={Color.ColdGrey_900}>Build Your Next Box</Heading4>
          <Text size={1}>Scheduled for {deliveryDate}</Text>
        </Join>
        <Box>
          <Join with={<Space direction="horizontal" size={1} />}>
            {isDeliveryFree && renderPill('FREE delivery for a year')}
            {hasDiscount && renderPill(`${percentageOff}% OFF`)}
          </Join>
        </Box>
        <Box display={Display.Flex}>
          <Join with={<Space direction="horizontal" size={1} />}>
            <RecipeImageTiles orderId={order.id} numPortions={Number(numPortions)} />
          </Join>
        </Box>
        <Space direction="vertical" size={2} />
        <Box display={Display.Flex} flexDirection={FlexDirection.Column}>
          <Heading5 color={Color.ColdGrey_900}>Next box best sellers</Heading5>
          <Carousel order={order} />
        </Box>
        <Button width="100%" onClick={handleChooseRecipes}>
          Choose recipes
        </Button>
      </Join>
    </Box>
  )
}
