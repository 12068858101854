import React, { CSSProperties } from 'react'

import {
  Body1,
  Box,
  Button,
  ButtonColorVariant,
  ButtonVariant,
  Color,
  Body2,
  Image,
  RadiusScale,
  useTheme,
  Display,
} from '@gousto-internal/citrus-react'
import { RootStateOrAny, useSelector } from 'react-redux'

import { MenuApiPreferencesResponse } from '@library/menu-service'
import { MenuRecipe } from '@library/menu-service/queries/common/types'
import { queueTrackingEvent } from '@library/tracking-integration'

import { getCoreRecipeIdByUuid } from 'selectors/recipe'

import { TrackCTAClick } from '../types'
import { InvalidPreferenceTag } from './InvalidPreferenceTag'

const IMAGE_WIDTH = 200

export type SearchRecipeTileProps = {
  addRecipe: (
    recipeId: string,
    view?: string,
    recipeInfo?: { position: string },
    maxRecipesNum?: number,
    orderId?: string,
    isOrderRecipe?: boolean,
  ) => void
  isMenuPreferencesWebEnabled: boolean
  isRecipeInBasket: (recipeId: string) => boolean
  menuPreferences?: MenuApiPreferencesResponse
  onClick: () => void
  reachedLimit: boolean
  recipe: MenuRecipe
  removeRecipe: (recipeId: string, view?: string, position?: string) => void
  trackingData: {
    invocationId?: string
    menuId: string
    index?: number
  }
}

const SearchRecipeTile = ({
  addRecipe,
  isMenuPreferencesWebEnabled,
  isRecipeInBasket,
  menuPreferences,
  onClick,
  reachedLimit,
  recipe,
  removeRecipe,
  trackingData,
}: SearchRecipeTileProps) => {
  const { theme } = useTheme()
  const recipeImage = recipe.images?.[0]?.crops?.find(({ width }) => width === IMAGE_WIDTH) ?? null
  const coreRecipeId = useSelector((state: RootStateOrAny) =>
    getCoreRecipeIdByUuid(state, recipe.id),
  )
  if (!coreRecipeId) return null

  const isInBasket = isRecipeInBasket(coreRecipeId)

  const trackingProperties: TrackCTAClick['properties'] = {
    search_invocation_id: trackingData.invocationId,
    search_rank: trackingData.index,
    menu_id: trackingData.menuId,
    add_type: 'search',
    recipe_id: recipe.id,
  }

  const onClickCTA = (event: React.SyntheticEvent) => {
    event.stopPropagation()
    if (isInBasket) {
      removeRecipe(coreRecipeId)
      queueTrackingEvent<TrackCTAClick>({
        action: 'remove_recipe',
        properties: trackingProperties,
      })
    } else {
      addRecipe(coreRecipeId)
      queueTrackingEvent<TrackCTAClick>({
        action: 'add_recipe',
        properties: trackingProperties,
      })
    }
  }

  const onKeyDown = (
    event: React.KeyboardEvent,
    callback: (event: React.KeyboardEvent) => void,
  ) => {
    if (event.code === 'Space' || event.code === 'Enter') {
      event.stopPropagation()
      event.preventDefault()
      callback(event)
    }
  }

  return (
    <Box
      aria-label="View recipe details"
      display={Display.Flex}
      onClick={onClick}
      onKeyDown={(event) => onKeyDown(event, onClick)}
      role="button"
      style={{ cursor: 'pointer' }}
      tabIndex={0}
    >
      <Box borderRadius={1}>
        {recipeImage ? (
          <Image
            alt={recipe.name}
            src={recipeImage.url}
            width="120px"
            height="120px"
            style={{ ...(theme.radius(2 as RadiusScale) as CSSProperties) }}
            data-testid="search-recipe-tile"
          />
        ) : (
          <Box width="120px" height="120px" />
        )}
      </Box>
      <Box flexGrow={1} paddingH={4}>
        <Body1 fontWeight={700} color={Color.ColdGrey_800}>
          {recipe.name}
        </Body1>
        <Box display={Display.Flex}>
          <Body2>{`${recipe.prep_time} mins`}</Body2>
          {(!menuPreferences || menuPreferences?.display_calories) && (
            <Body2>
              &nbsp;&nbsp;|&nbsp;&nbsp;
              {`${recipe.nutritional_information.per_portion.energy_kcal}kcal`}
            </Body2>
          )}
        </Box>
        {isMenuPreferencesWebEnabled && recipe.invalid_preference && (
          <Box paddingV={1}>
            <InvalidPreferenceTag text={recipe.invalid_preference} />
          </Box>
        )}
      </Box>
      <Box>
        <Button
          variant={ButtonVariant.Icon}
          colorVariant={isInBasket ? ButtonColorVariant.Secondary : ButtonColorVariant.Primary}
          icon={isInBasket ? 'remove' : 'add'}
          aria-label={isInBasket ? 'Remove recipe' : 'Add recipe'}
          onClick={onClickCTA}
          onKeyDown={(event) => onKeyDown(event, onClickCTA)}
          tabIndex={0}
          disabled={reachedLimit && !isInBasket}
        />
      </Box>
    </Box>
  )
}

export { SearchRecipeTile }
