const HEADING_USE_SENTENCE_CASE = 'headingUseSentenceCase'
const HIDE_CHOOSE_RECIPES_CTA = 'hideChooseRecipesCta'
const HIDE_RECIPE_LIST = 'hideRecipeList'
const HIDE_RECIPE_TITLE_ARROW = 'hideRecipeTitleArrow'
const HIDE_PORTIONS = 'hidePortions'
const HIDE_PROMO_CODE_TEXT = 'hidePromoCodeText'
const HIDE_DELIVERY_COPY = 'hideDeliveryCopy'
const HIDE_PRICE_PER_SERVING_MESSAGE = 'hidePricePerServingMessage'
const HIDE_RECIPES_WHEN_NO_SELECTIONS = 'hideRecipesWhenNoSelections'
const LEFT_ALIGN_HEADING = 'leftAlignHeading'
const REVERSE_HEADER_COMPONENTS_ORDER = 'reverseHeaderComponentsOrder'
const SHOW_ALTERNATE_ORDER_TOTAL = 'alternateOrderTotal'
const SQUARE_RECIPE_IMAGES = 'squareRecipeImages'
const SHOW_ALIGNED_DATE_HEADER_BUTTON = 'alignDateHeaderItemsSpaceBetween'

export {
  HEADING_USE_SENTENCE_CASE,
  HIDE_CHOOSE_RECIPES_CTA,
  HIDE_DELIVERY_COPY,
  HIDE_PORTIONS,
  HIDE_PRICE_PER_SERVING_MESSAGE,
  HIDE_PROMO_CODE_TEXT,
  HIDE_RECIPE_LIST,
  HIDE_RECIPE_TITLE_ARROW,
  HIDE_RECIPES_WHEN_NO_SELECTIONS,
  LEFT_ALIGN_HEADING,
  REVERSE_HEADER_COMPONENTS_ORDER,
  SHOW_ALTERNATE_ORDER_TOTAL,
  SHOW_ALIGNED_DATE_HEADER_BUTTON,
  SQUARE_RECIPE_IMAGES,
}
