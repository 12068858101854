import React from 'react'

import { LayoutContentWrapper } from 'goustouicomponents'
import Immutable from 'immutable'
import PropTypes from 'prop-types'

import { Item } from 'components/Item'
import { isAvailableRecipeList } from 'utils/recipe'

import { UnavailableMessage } from '../UnavailableMessage'

import css from '../Details.css'

class RecipeList extends React.PureComponent {
  componentDidMount() {
    const { trackingUnavailableRecipeList, menuFetchPending, unavailableRecipeIds, recipesStore } =
      this.props
    const unavailableRecipeList = isAvailableRecipeList(unavailableRecipeIds, recipesStore)

    if (unavailableRecipeList.size > 0 && !menuFetchPending) {
      trackingUnavailableRecipeList(unavailableRecipeList)
    }
  }

  render() {
    const {
      basketRecipes,
      menuFetchPending,
      numPortions,
      okRecipeIds,
      removeRecipeFromBasket,
      orderSaveError,
      recipesStore,
      showRecipeDetailsOnClick,
      unavailableRecipeIds,
      basketRestorePreviousDate,
      clearSlot,
      forceSquareRecipeImages,
      hideRecipeTitleArrow,
    } = this.props
    const okRecipeList = isAvailableRecipeList(okRecipeIds, recipesStore)
    const unavailableRecipeList = isAvailableRecipeList(unavailableRecipeIds, recipesStore)
    const renderRecipesInBasket = () =>
      okRecipeList
        .map((recipe) => (
          <Item
            key={recipe.get('id')}
            type="recipe"
            available
            fromBox
            media={recipe.getIn(['media', 'images', 0, 'urls'], Immutable.List([]))}
            onImageClick={() => showRecipeDetailsOnClick(recipe.get('id'))}
            onRemove={() => removeRecipeFromBasket(recipe.get('id'), 'boxSummaryMinus')}
            quantity={basketRecipes.get(recipe.get('id')) * numPortions}
            recipeId={recipe.get('id')}
            title={recipe.get('title')}
            forceSquareImage={forceSquareRecipeImages}
            hideArrowRight={hideRecipeTitleArrow}
          />
        ))
        .toArray()

    return (
      <div>
        <LayoutContentWrapper>
          <div className={css.recipeItems}>
            {renderRecipesInBasket()}
            <UnavailableMessage
              basketRestorePreviousDate={basketRestorePreviousDate}
              clearSlot={clearSlot}
              unavailableRecipeList={unavailableRecipeList}
              menuFetchPending={menuFetchPending}
              orderSaveError={orderSaveError}
            />

            {unavailableRecipeList
              .map((recipe) => (
                <Item
                  key={recipe.get('id')}
                  type="recipe"
                  available={menuFetchPending}
                  media={recipe.getIn(['media', 'images', 0, 'urls'], Immutable.List([]))}
                  onImageClick={() => showRecipeDetailsOnClick(recipe.get('id'))}
                  onRemove={() => removeRecipeFromBasket(recipe.get('id'), 'boxSummaryMinus')}
                  quantity={basketRecipes.get(recipe.get('id')) * numPortions}
                  title={recipe.get('title')}
                />
              ))
              .toArray()}
          </div>
        </LayoutContentWrapper>
      </div>
    )
  }
}

RecipeList.propTypes = {
  basketRecipes: PropTypes.instanceOf(Immutable.Map).isRequired,
  menuFetchPending: PropTypes.bool.isRequired,
  numPortions: PropTypes.number.isRequired,
  okRecipeIds: PropTypes.instanceOf(Immutable.Map).isRequired,
  removeRecipeFromBasket: PropTypes.func.isRequired,
  orderSaveError: PropTypes.string.isRequired,
  recipesStore: PropTypes.instanceOf(Immutable.Map).isRequired,
  showRecipeDetailsOnClick: PropTypes.func.isRequired,
  unavailableRecipeIds: PropTypes.instanceOf(Immutable.Map).isRequired,
  basketRestorePreviousDate: PropTypes.func.isRequired,
  clearSlot: PropTypes.func.isRequired,
  trackingUnavailableRecipeList: PropTypes.func.isRequired,
  forceSquareRecipeImages: PropTypes.bool,
  hideRecipeTitleArrow: PropTypes.bool,
}

RecipeList.defaultProps = {
  forceSquareRecipeImages: false,
  hideRecipeTitleArrow: false,
}

export { RecipeList }
