import React from 'react'

import {
  Grid,
  Col,
  Heading2,
  Box,
  Body2,
  Color,
  Display,
  AlignItems,
  Space,
  Image,
  JustifyContent,
  Join,
} from '@gousto-internal/citrus-react'

import { ReferAFriend } from 'routes/OrderConfirmation/components/ReferAFriend'

import { AppPromoV2 } from '../AppPromoV2'
import { BuildYourNextBoxContainer } from '../BuildYourNextBox/BuildYourNextBoxContainer'
import { YourOrderSummary } from '../YourOrderSummary'
import WelcomeGoustoBox from '../assets/Box_icon.svg'

function renderMobileLayout(
  renderedOrderSummary: React.JSX.Element,
  renderedBuildYourNextBox: React.JSX.Element,
  renderedReferAFriend: React.JSX.Element,
  renderedAppPromo: React.JSX.Element,
) {
  return (
    <Col size={12} display={['block', 'block', 'none', 'none']}>
      <Join with={<Space size={6} />}>
        <Box>{renderedOrderSummary}</Box>
        <Box bg={Color.White} paddingV={5} paddingH={3}>
          {renderedBuildYourNextBox}
        </Box>
        <Box
          bg={Color.White}
          display={Display.Flex}
          paddingV={5}
          paddingH={3}
          data-testing="appPromo"
        >
          {renderedAppPromo}
        </Box>
        <Box
          bg={Color.White}
          display={Display.Flex}
          alignItems={AlignItems.Center}
          data-testing="referAFriendSection"
        >
          {renderedReferAFriend}
        </Box>
      </Join>
    </Col>
  )
}

function renderTabletAndDesktopLayout(
  renderedOrderSummary: React.JSX.Element,
  renderedBuildYourNextBox: React.JSX.Element,
  renderedReferAFriend: React.JSX.Element,
  renderedAppPromo: React.JSX.Element,
) {
  return (
    <>
      <Col size={7} display={['none', 'none', 'block', 'block']}>
        <Join with={<Space size={6} />}>
          <Box bg={Color.White} paddingV={5} paddingH={3}>
            {renderedBuildYourNextBox}
          </Box>
          <Box
            bg={Color.White}
            display={['none', 'none', Display.Flex, 'none']}
            paddingV={5}
            paddingH={3}
          >
            {renderedAppPromo}
          </Box>
          <Box
            bg={Color.White}
            display={['none', 'none', Display.Flex, 'none']}
            alignItems={AlignItems.Center}
          >
            {renderedReferAFriend}
          </Box>
        </Join>
      </Col>
      <Col size={5} display={['none', 'none', 'block', 'block']}>
        <Join with={<Space size={6} />}>
          <Box>{renderedOrderSummary}</Box>
          <Box
            bg={Color.White}
            display={['none', 'none', 'none', Display.Flex]}
            paddingV={5}
            paddingH={3}
            data-testing="appPromo"
          >
            {renderedAppPromo}
          </Box>
          <Box
            bg={Color.White}
            display={['none', 'none', 'none', Display.Flex]}
            alignItems={AlignItems.Center}
            data-testing="referAFriendSection"
          >
            {renderedReferAFriend}
          </Box>
        </Join>
      </Col>
    </>
  )
}

interface WelcomeFirstBoxOrderWrapperProps {
  nameFirst: string
  userEmail: string
  discountSofteningFreeDeliveryValue: string
  isSignUpFirstTwoBoxesExperimentEnabled: boolean
  trackWelcomeAppPromoClick: () => void
}

export function WelcomeFirstBoxOrderWrapper({
  nameFirst,
  userEmail,
  discountSofteningFreeDeliveryValue,
  isSignUpFirstTwoBoxesExperimentEnabled,
  trackWelcomeAppPromoClick,
}: WelcomeFirstBoxOrderWrapperProps) {
  const renderedOrderSummary = (
    <YourOrderSummary discountSofteningFreeDeliveryValue={discountSofteningFreeDeliveryValue} />
  )

  const renderedBuildYourNextBox = <BuildYourNextBoxContainer />

  const renderedReferAFriend = (
    <ReferAFriend isSignUpFirstTwoBoxesExperimentEnabled={isSignUpFirstTwoBoxesExperimentEnabled} />
  )
  const renderedAppPromo = <AppPromoV2 trackWelcomeAppPromoClick={trackWelcomeAppPromoClick} />

  return (
    <Grid
      paddingH={[3, 5, 3]}
      paddingV={[3, 5, 3]}
      paddingBottom={[3, 5, 3]}
      paddingRight={[3, 5, 3]}
      gap={6}
    >
      <Col size={12}>
        <Box>
          <Box
            display={Display.Flex}
            alignItems={AlignItems.Center}
            justifyContent={JustifyContent.Center}
          >
            <Image
              width="178px"
              height="125px"
              src={WelcomeGoustoBox}
              alt="Gousto logo"
              data-testid="welcome-gousto-box-image"
            />
          </Box>
          <Heading2>Congrats on ordering your first box, {nameFirst}</Heading2>
        </Box>
        <Box>
          <Body2>
            We&apos;ll send your order details shortly, as well as information about your
            subscription to {userEmail}
          </Body2>
        </Box>
        <Space size={6} />
      </Col>

      {renderMobileLayout(
        renderedOrderSummary,
        renderedBuildYourNextBox,
        renderedAppPromo,
        renderedReferAFriend,
      )}
      {renderTabletAndDesktopLayout(
        renderedOrderSummary,
        renderedBuildYourNextBox,
        renderedAppPromo,
        renderedReferAFriend,
      )}
    </Grid>
  )
}
