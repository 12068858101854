import React from 'react'

import {
  AlignItems,
  Body2,
  BorderStyle,
  Box,
  Color,
  Display,
  FontWeight,
  Icon,
  IconVariant,
  Pill,
  PillProps,
  Space,
  withCitrusTheme,
} from '@gousto-internal/citrus-react'

const onSpaceOrEnter =
  (callback: (event: React.KeyboardEvent) => void) => (event: React.KeyboardEvent) => {
    if (event.code === 'Space' || event.code === 'Enter') {
      event.preventDefault()
      callback(event)
    }
  }

interface CustomPillProps extends PillProps {
  selected: boolean
}

const CustomPill = withCitrusTheme(
  Pill as React.ComponentType<CustomPillProps>,
  (theme, { selected }: CustomPillProps) => ({
    backgroundColor: theme.color(selected ? Color.Secondary_400 : Color.ColdGrey_50),
    cursor: 'pointer',

    '&:focus, &:focus-visible, &:hover': {
      backgroundColor: theme.color(selected ? Color.Secondary_600 : Color.ColdGrey_100),
      marginTop: 0,
      marginLeft: 0,
      outline: 'none',
    },
  }),
)

type SelectablePillProps = {
  onChange: (selected: boolean) => void
  selected: boolean
  text: string
}

export const SelectablePill = ({ onChange, selected, text }: SelectablePillProps) => {
  const onClick = () => onChange(!selected)

  return (
    <CustomPill
      onClick={onClick}
      onKeyDown={onSpaceOrEnter(onClick)}
      role="button"
      selected={selected}
      tabIndex={0}
    >
      <Box alignItems={AlignItems.Center} display={Display.Flex} paddingH={0.5} paddingV={0.5}>
        <Body2 color={selected ? Color.White : Color.ColdGrey_800} fontWeight={FontWeight.Bold}>
          {text}
        </Body2>
        <Space direction="horizontal" size={1} />
        {selected ? (
          <Icon name="success" size={4} style={{ color: Color.White }} />
        ) : (
          <Box
            bg={Color.White}
            borderStyle={BorderStyle.Solid}
            borderRadius="round"
            display={Display.Flex}
          >
            <Icon name="remove" variant={IconVariant.Actionable} size={4} />
          </Box>
        )}
      </Box>
    </CustomPill>
  )
}
