import React, { useCallback, useLayoutEffect, useState } from 'react'

import { Body3, Color, Display, Text } from '@gousto-internal/citrus-react'
import { usePrevious } from 'react-use'

import { useIsRightSizingExperimentEnabled } from 'hooks/useIsRightSizingExperimentEnabled'
import { useBasket, useSupportedBoxTypes } from 'routes/Menu/domains/basket'
import { useIsActionBarRedesignEnabled } from 'routes/Menu/hooks/useIsActionBarRedesignEnabled'

import css from './CheckoutCounter.css'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const classNames = require('classnames')

export interface CheckoutCounterProps {
  /**
   * If true, counter would be colored as disabled.
   */
  isDisabled?: boolean
  isButtonHovered: boolean
  numRecipes?: number
}

/**
 * Displays animated square with active orders amount out of max orders (e.g. "3 / 4")
 */
export const CheckoutCounter = ({
  isDisabled = false,
  isButtonHovered,
  numRecipes,
}: CheckoutCounterProps) => {
  const [shouldPlayAnimation, setShouldPlayAnimation] = useState<boolean>(false)
  const oldCounterValue = usePrevious(numRecipes)
  const onAnimationEnd = useCallback(() => {
    setShouldPlayAnimation(false)
  }, [])

  useLayoutEffect(() => {
    if (oldCounterValue !== numRecipes) {
      setShouldPlayAnimation(true)
    }
  }, [oldCounterValue, numRecipes])

  const { numPortions } = useBasket()
  const { maxRecipesForPortion } = useSupportedBoxTypes()
  const recipeLimit = maxRecipesForPortion(numPortions)
  const isActionBarRedesignEnabled = useIsActionBarRedesignEnabled()
  const isRightSizingExperimentEnabled = useIsRightSizingExperimentEnabled()

  return (
    <div
      className={classNames(css.checkoutCounter, {
        [css.isDisabled]: isDisabled,
      })}
    >
      <Text
        data-testid="CheckoutCounter_background"
        className={classNames(css.checkoutCounterBackground, {
          [css.isButtonHovered]: isButtonHovered,
          [css.scaleAndWiggleAnimation]: shouldPlayAnimation,
        })}
        onAnimationEnd={onAnimationEnd}
      />
      <Body3
        data-testid="CheckoutCounter_content"
        className={classNames({
          [css.checkoutCounterValue]: true,
          [css.wiggleAnimation]: shouldPlayAnimation,
        })}
        color={Color.White}
      >
        <Body3 display={Display.Inline} color={Color.White}>
          {numRecipes}
        </Body3>
        {!isActionBarRedesignEnabled && (
          <>
            {!isRightSizingExperimentEnabled && (
              <>
                <Body3 className={css.slash} display={Display.Inline} color={Color.White}>
                  /
                </Body3>
                <Body3 display={Display.Inline} color={Color.White}>
                  {recipeLimit}
                </Body3>
              </>
            )}
          </>
        )}
      </Body3>
    </div>
  )
}
