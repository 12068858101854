import Immutable from 'immutable'
import { RootStateOrAny } from 'react-redux'
import { Dispatch, AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'

import { Branded } from '@library/type-utils'

import * as boxPricesApi from 'apis/boxPrices'
import * as orderV2 from 'routes/Menu/apis/orderV2'
import { GetState } from 'routes/Menu/fetchData/types'
import { ImmutableMap } from 'routes/Menu/types/immutableMap'
import { getBasketOrderId, getBasketTariffId, getPromoCode } from 'selectors/basket'
import GoustoException from 'utils/GoustoException'
import { limitReached } from 'utils/basket'

import { actionTypes } from './actionTypes'
import {
  basketChosenAddressChange,
  basketDateChange,
  basketIdChange,
  basketNumPortionChange,
  basketOrderLoaded,
  basketPostcodeChange,
  basketProductAdd,
  basketReset,
  basketSlotChange,
} from './basket'
import { loadMenuCollectionsWithMenuService } from './menuActionHelper'
import { productsLoadCategories, productsLoadProductsById, productsLoadStock } from './products'
import statusActions from './status'
import tempActions from './temp'
import * as trackingKeys from './trackingKeys'

export type TariffId = Branded<string, 'TariffId'>

export type RecipeId = Branded<string, 'RecipeId'>

type Pending = Branded<boolean, 'Pending'>

export type DeliveryDays = Immutable.Map<
  string,
  ImmutableMap<{
    slots: Immutable.List<
      ImmutableMap<{
        coreSlotId: string
        id: string
      }>
    >
  }>
>

export type CoreSlotId = Branded<string, 'CoreSlotId'>

export type CutOffUntil = Branded<string, 'CutOffUntil'>

type ReqData = {
  order_id?: string
  promocode?: string
  tariff_id?: string
}

export type OrderId = Branded<string, 'OrderId'>

type AddRecipe = {
  (
    recipeId: RecipeId,
    view: string | undefined,
    recipeInfo:
      | {
          position: string
        }
      | undefined,
    maxRecipesNum: number | undefined,
    orderId: OrderId,
    isOrderRecipe?: boolean,
  ): void
}

export const menuReceiveBoxPrices = (prices: boxPricesApi.BoxPrices, tariffId?: TariffId) =>
  tariffId
    ? {
        type: actionTypes.MENU_BOX_PRICES_RECEIVE,
        prices,
        tariffId,
      }
    : {
        type: actionTypes.MENU_BOX_PRICES_RECEIVE,
        prices,
      }

export const menuReceiveMenuPending = (pending: Pending) => ({
  type: actionTypes.MENU_RECIPES_RECEIVE_PENDING,
  pending,
})

export const findSlot = (deliveryDays: DeliveryDays, coreSlotId: CoreSlotId) => {
  let slotId
  deliveryDays.some((deliveryDay) => {
    const matchedSlot = deliveryDay?.get('slots').find((slot) => {
      if (String(slot?.get('coreSlotId')) === String(coreSlotId)) {
        return true
      }

      return false
    })

    if (matchedSlot) {
      slotId = matchedSlot.get('id')

      return true
    }

    return false
  })

  return slotId
}

export const menuCutoffUntilReceive = (cutoffUntil: CutOffUntil) => ({
  type: actionTypes.MENU_CUTOFF_UNTIL_RECEIVE,
  cutoffUntil,
})

export const menuLoadMenu =
  () => (dispatch: ThunkDispatch<RootStateOrAny, unknown, AnyAction>, getState: GetState) => {
    const state = getState()

    loadMenuCollectionsWithMenuService(dispatch, getState)

    const reachedLimit = limitReached(state.basket, state.menuRecipes, state.menuService)
    dispatch({
      type: actionTypes.BASKET_LIMIT_REACHED,
      limitReached: reachedLimit,
    })
  }

export const menuLoadOrderDetails =
  (orderId: OrderId, addRecipe: AddRecipe) =>
  async (dispatch: ThunkDispatch<RootStateOrAny, unknown, AnyAction>, getState: GetState) => {
    try {
      dispatch(statusActions.pending(actionTypes.LOADING_ORDER, true))

      const { data: order } = await orderV2.fetchOrder(getState, orderId)
      dispatch(basketReset())
      dispatch(basketDateChange(order.deliveryDate))
      dispatch(basketNumPortionChange(order.box.numPortions, orderId))

      order.recipeItems.forEach((recipe: { quantity: string; recipeId: string }) => {
        const qty = Math.round(parseInt(recipe.quantity, 10) / parseInt(order.box.numPortions, 10))

        for (let i = 1; i <= qty; i++) {
          // fall back to the defaults for these 3 params
          const view = undefined
          const recipeInfo = undefined
          const maxRecipesNum = undefined

          addRecipe(
            recipe.recipeId as RecipeId,
            view,
            recipeInfo,
            maxRecipesNum,
            orderId,
            true, // isOrderRecipe is true to skip stock check
          )
        }
      })

      const productItems = order.productItems || []
      if (productItems.length) {
        const productItemIds = productItems.map(
          (productItem: { itemableId: string }) => productItem.itemableId,
        )
        await dispatch(productsLoadProductsById(productItemIds))
        await dispatch(productsLoadStock())
        await dispatch(productsLoadCategories())
        productItems.forEach((product: { quantity: string; itemableId: string }) => {
          for (let i = 0; i < parseInt(product.quantity, 10); i++) {
            dispatch(basketProductAdd(product.itemableId))
          }
        })
      }

      dispatch(basketIdChange(order.id))
      dispatch(basketOrderLoaded(order.id))
      dispatch(basketChosenAddressChange(order.shippingAddress))

      const grossTotal = order && order.prices && order.prices.grossTotal
      const netTotal = order && order.prices && order.prices.total

      dispatch(tempActions.temp('originalGrossTotal', grossTotal))
      dispatch(tempActions.temp('originalNetTotal', netTotal))

      // This sets `boxSummaryDeliveryDays`
      await dispatch(basketPostcodeChange(order.shippingAddress.postcode))

      const newState = getState()
      const coreSlotId = order.deliverySlot.id
      const slotId = findSlot(newState.boxSummaryDeliveryDays, coreSlotId)
      dispatch(basketSlotChange(slotId))
    } finally {
      dispatch(statusActions.pending(actionTypes.LOADING_ORDER, false))
    }
  }

export const menuBrowseCTAVisibilityChange = (show: boolean) => {
  const trackingData = {
    actionType: trackingKeys.showBrowseCta,
    show,
  }

  return {
    type: actionTypes.MENU_BROWSE_CTA_VISIBILITY_CHANGE,
    show,
    ...(show ? { trackingData } : {}),
  }
}

export const menuPreferencesVisibilityChange = (show: boolean) => {
  const trackingData = {
    actionType: trackingKeys.showMenuPreferences,
    show,
  }

  return {
    type: actionTypes.MENU_PREFERENCES_VISIBILITY_CHANGE,
    show,
    ...(show ? { trackingData } : {}),
  }
}

export const menuLoadComplete = (timeToLoadMs: number, useMenuService: boolean) => ({
  type: actionTypes.MENU_LOAD_COMPLETE,
  timeToLoadMs,
  useMenuService,
})

export const trackVariantListDisplay = (view: string) => ({
  type: actionTypes.TRACK_VARIANT_RECIPE_LIST_DISPLAY,
  trackingData: {
    actionType: trackingKeys.recipeVariantActionSheet,
    view,
  },
})

export const menuLoadBoxPrices = () => async (dispatch: Dispatch, getState: GetState) => {
  try {
    const state = getState()
    const promoCode = getPromoCode(state)
    const orderId = getBasketOrderId(state)
    const tariffId = getBasketTariffId(state)
    const reqData: ReqData = {}

    if (orderId) {
      reqData.order_id = orderId
    } else if (promoCode) {
      reqData.promocode = promoCode
    }

    if (!getState().auth.get('isAuthenticated') && tariffId) {
      reqData.tariff_id = tariffId
    }

    dispatch(statusActions.pending(actionTypes.MENU_BOX_PRICES_RECEIVE, true))
    dispatch(statusActions.error(actionTypes.MENU_BOX_PRICES_RECEIVE, false))

    try {
      const { data: recipePrices } = await boxPricesApi.fetchBoxPrices(
        getState().auth.get('accessToken'),
        reqData,
      )
      dispatch(menuReceiveBoxPrices(recipePrices, tariffId))
    } catch (err) {
      dispatch(menuReceiveBoxPrices({}))

      throw new GoustoException(
        `Could not load menu box prices: fetch failed${
          tariffId ? ` for tariff_id "${tariffId}"` : ''
        }, ${err}`,
        {
          error: 'fetch-failed',
        },
      )
    }
  } catch (err: any) {
    const errMessage = err.message || err
    dispatch(statusActions.error(actionTypes.MENU_BOX_PRICES_RECEIVE, err.error || errMessage))
  } finally {
    dispatch(statusActions.pending(actionTypes.MENU_BOX_PRICES_RECEIVE, false))
  }
}
