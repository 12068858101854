import { client } from 'config/routes'
import { useAuth } from 'routes/Menu/domains/auth'

import { CtaTexts } from './data'

/**
 * Used to get cta text & uri based on condition
 * if user logged in or not
 */

export const useGetCtaProps = ({ withDiscount = false }: { withDiscount?: boolean } = {}) => {
  const { isAuthenticated } = useAuth()
  const { signup, deliveryDetails } = client

  const dynamicCtaText = () =>
    withDiscount ? CtaTexts.GET_STARTED_WITH_DISCOUNT : CtaTexts.GET_STARTED

  return isAuthenticated
    ? {
        ctaUri: deliveryDetails,
        ctaText: CtaTexts.OPEN_MENU,
      }
    : {
        ctaUri: signup,
        ctaText: dynamicCtaText(),
      }
}
