import React, { useState } from 'react'

import {
  AlignItems,
  Box,
  Display,
  FlexDirection,
  JustifyContent,
  Space,
  Heading1,
  Button,
} from '@gousto-internal/citrus-react'
import { PortionSizeSelector } from 'PortionSizeSelector/PortionSizeSelector'
import { PricingTransparencyPortionSelector } from 'PricingTransparencyPortionSelector/PricingTransparencyPortionSelector'
import { useDispatch, useSelector } from 'react-redux'

import { FlagProblematicRendering } from '@library/e2e-util-problematic-rendering'

import { basketNumPortionChange, portionSizeSelectedTracking } from 'actions/basket'
import { trackBoxSizeSelectionChange } from 'actions/boxSummary'
import { completeWizardBoxSize } from 'actions/trackingKeys'
import { RibbonTriggerContainer } from 'components/RibbonTrigger'
import { useIsPricingTransparencyEnabled } from 'hooks/useIsPricingTransparencyEnabled'
import { NumberOfPortions } from 'routes/Menu/domains/basket'
import { trackSignupWizardAction } from 'routes/Signup/signupActions'
import { signupConfig } from 'routes/Signup/signupConfig'
import { getNumPortions, getAvailableNumPortions } from 'selectors/basket'

type BoxSizeStepProps = {
  next: () => void
}

export const BoxSizeStep = ({ next }: BoxSizeStepProps) => {
  const dispatch = useDispatch()
  const { title } = signupConfig.boxSizeStep
  const numPortions = useSelector(getNumPortions)
  const [activePortionSize, setActivePortionSize] = useState(numPortions)
  const getAvailablePortionSize = useSelector(getAvailableNumPortions)
  const defaultBoxSize = [2, 4]

  const isPricingTransparencyEnabled = useIsPricingTransparencyEnabled()

  const handlePrimaryButtonClick = (value: NumberOfPortions) => {
    dispatch(basketNumPortionChange(value, 'wizard'))
    dispatch(portionSizeSelectedTracking(value))
    dispatch(
      trackSignupWizardAction(completeWizardBoxSize, {
        box_size: value,
      }),
    )
    next()
  }

  const handleOnChange = (value: NumberOfPortions) => {
    setActivePortionSize(value)
    dispatch(trackBoxSizeSelectionChange(value, 'wizard'))
  }

  return (
    <Box
      display={Display.Flex}
      flexDirection={FlexDirection.Column}
      justifyContent={JustifyContent.SpaceBetween}
      alignItems={AlignItems.Center}
      data-testing="signupBoxSizeWithRadioStep"
    >
      <FlagProblematicRendering label="boxSizeStepRenderingProblem" />
      <Box width="100%">
        <Heading1>{title}</Heading1>
      </Box>
      <Box width={isPricingTransparencyEnabled ? '100%' : ['100%', '400px', null, '400px']}>
        <Space size={4} direction="vertical" />
        {isPricingTransparencyEnabled ? (
          <PricingTransparencyPortionSelector
            portionSize={activePortionSize}
            availablePortionSizes={getAvailablePortionSize || defaultBoxSize}
            onChange={(e) => handleOnChange(Number(e.target.value))}
          />
        ) : (
          <PortionSizeSelector
            portionSize={activePortionSize}
            availablePortionSizes={getAvailablePortionSize || defaultBoxSize}
            onChange={(e) => handleOnChange(Number(e.target.value))}
          />
        )}
        <Space size={5} />
        <Button
          height="48px"
          width="100%"
          data-testing="signupBoxSizeWithRadioStepContinue"
          onClick={() => handlePrimaryButtonClick(activePortionSize)}
        >
          Continue
        </Button>
      </Box>
      <RibbonTriggerContainer name="wizard-box-size" />
    </Box>
  )
}
