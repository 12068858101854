import React, { SyntheticEvent, useEffect, useState } from 'react'

import {
  AlignItems,
  Box,
  Button,
  Color,
  Display,
  Divider,
  FlexDirection,
  Heading3,
  Icon,
  IconVariant,
  JustifyContent,
  Overflow,
} from '@gousto-internal/citrus-react'
import { useDispatch, useSelector } from 'react-redux'

import { MenuSettingsRequestPayload } from '@library/menu-service'

import { useMenuPreferences } from 'routes/Menu/domains/menu'
import fetchData from 'routes/Menu/fetchData'
import { getUserFirstName } from 'selectors/user'
import { onEnter } from 'utils/accessibility'

import { DietaryPreferences } from './DietaryPreferences'
import { EquipmentPreferences } from './EquipmentPreferences'
import { PreferencesAlert } from './PreferencesAlert'
import { RecipeInformation } from './RecipeInformation'

type MenuPreferencesFormProps = {
  onClose: (event: SyntheticEvent) => void
}

export const MenuPreferencesForm = ({ onClose }: MenuPreferencesFormProps) => {
  const dispatch = useDispatch()
  const userFirstName = useSelector(getUserFirstName)
  const { updateMenuPreferences, data, isLoading } = useMenuPreferences()

  const [formPreferences, setFormPreferences] = useState<MenuSettingsRequestPayload>({
    dietary_preferences: [],
    equipment: [],
    display_calories: true,
  })

  const handleSave = async (event: SyntheticEvent) => {
    await updateMenuPreferences(formPreferences)
    dispatch(fetchData({ query: {}, params: {} }, true, false, { addRecipe: () => {} }))
    onClose(event)
  }

  useEffect(() => {
    if (data) {
      setFormPreferences(data)
    }
  }, [data])

  return data ? (
    <Box height="100%" display={Display.Flex} flexDirection={FlexDirection.Column}>
      <Box
        alignItems={AlignItems.Center}
        bg={Color.White}
        display={Display.Flex}
        justifyContent={JustifyContent.SpaceBetween}
        paddingH={6}
        paddingV={4}
      >
        <Heading3>{userFirstName}&apos;s preferences</Heading3>
        <Box
          data-testid="close-button"
          display={Display.Flex}
          role="button"
          tabIndex={0}
          onClick={onClose}
          onKeyDown={onEnter(onClose)}
        >
          <Icon name="close" size={6} variant={IconVariant.Actionable}>
            Close menu preferences
          </Icon>
        </Box>
      </Box>
      <Box paddingBottom={2}>
        <Divider />
      </Box>
      <Box paddingH={6} overflowY={Overflow.Scroll}>
        <PreferencesAlert />
        <DietaryPreferences
          dietaryPreferences={formPreferences.dietary_preferences}
          onChange={(dietaryPreferences) => {
            setFormPreferences({ ...formPreferences, dietary_preferences: dietaryPreferences })
          }}
        />
        <EquipmentPreferences
          equipmentPreferences={formPreferences.equipment}
          onChange={(equipmentPreferences) => {
            setFormPreferences({ ...formPreferences, equipment: equipmentPreferences })
          }}
        />
        <RecipeInformation
          displayCalories={formPreferences.display_calories}
          onChange={(displayCalories) =>
            setFormPreferences({ ...formPreferences, display_calories: displayCalories })
          }
        />
      </Box>
      <Box
        bg={Color.White}
        display={Display.Flex}
        flexDirection={FlexDirection.Column}
        paddingBottom={4}
      >
        <Box paddingBottom={4}>
          <Divider />
        </Box>
        <Box paddingH={6}>
          <Button disabled={isLoading} height="48px" onClick={handleSave} width="100%">
            Save
          </Button>
        </Box>
      </Box>
    </Box>
  ) : null
}
