import { connect } from 'react-redux'

import { getUserFirstName, getUserEmail } from 'selectors/user'

import { WelcomeFirstBoxOrderWrapper } from './WelcomeFirstBoxOrderWrapper'

function mapStateToProps(state: any) {
  const nameFirst = getUserFirstName(state)
  const userEmail = getUserEmail(state)

  return {
    nameFirst,
    userEmail,
  }
}

const WelcomeFirstBoxOrderWrapperContainer = connect(mapStateToProps)(WelcomeFirstBoxOrderWrapper)

export { WelcomeFirstBoxOrderWrapperContainer }
