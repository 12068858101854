import React from 'react'

import {
  Body2,
  Body3,
  Box,
  Checkbox,
  Color,
  Display,
  FlexDirection,
  FontWeight,
  Heading5,
  Space,
  Text,
} from '@gousto-internal/citrus-react'

type RecipeInformationProps = {
  displayCalories: boolean
  onChange: (displayCalories: boolean) => void
}

export const RecipeInformation = ({ displayCalories, onChange }: RecipeInformationProps) => (
  <Box paddingV={2}>
    <Heading5>Recipe information</Heading5>
    <Body2>Edit the information you see on recipe cards</Body2>
    <Space size={3} direction="vertical" />
    <Checkbox
      checked={displayCalories}
      id="show-calories-checkbox"
      onChange={() => onChange(!displayCalories)}
      outline
      paddingH={3}
      paddingV={3}
    >
      <Box display={Display.Flex} flexGrow={1} flexDirection={FlexDirection.Column}>
        <Text
          color={displayCalories ? Color.Secondary_400 : Color.ColdGrey_800}
          fontWeight={displayCalories ? FontWeight.Bold : FontWeight.Normal}
          size={3}
        >
          Calories (kcal)
        </Text>
        <Body3>Calories are always visible when you view more details of a recipe</Body3>
      </Box>
    </Checkbox>
  </Box>
)
