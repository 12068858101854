import React from 'react'

import {
  AlignItems,
  Box,
  Color,
  Icon,
  BorderStyle,
  IconVariant,
  Display,
  Heading6,
} from '@gousto-internal/citrus-react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { incrementTutorialViewed } from 'actions/tutorial'
import { onEnter } from 'utils/accessibility'

export const PREFERENCES_ALERT_NAME = 'menu_preferences_alert'

export const PreferencesAlert: React.FC = () => {
  const dispatch = useDispatch()

  const hasDismissedAlert = useSelector(({ tutorial }: RootStateOrAny) =>
    Boolean(tutorial && tutorial.getIn(['viewed', PREFERENCES_ALERT_NAME])),
  )

  if (hasDismissedAlert) {
    return null
  }

  const onClose = () => {
    dispatch(incrementTutorialViewed(PREFERENCES_ALERT_NAME))
  }

  return (
    <Box paddingV={2}>
      <Box
        display={Display.Flex}
        alignItems={AlignItems.FlexStart}
        bg={Color.Informative_50}
        paddingH={3}
        paddingV={4}
        borderWidth={1}
        borderColor={Color.Informative_200}
        borderRadius={1.5}
        borderStyle={BorderStyle.Solid}
      >
        <Icon name="info" variant={IconVariant.Informative} />
        <Box paddingRight={5} paddingH={3}>
          <Heading6>
            Your preferences won&apos;t be taken into account if you forget to choose
          </Heading6>
        </Box>
        <Box
          display={Display.Flex}
          role="button"
          tabIndex={0}
          onClick={onClose}
          onKeyDown={onEnter(onClose)}
        >
          <Icon name="close" size={6}>
            Close menu preferences alert
          </Icon>
        </Box>
      </Box>
    </Box>
  )
}
