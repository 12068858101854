import React, { SyntheticEvent, useEffect } from 'react'

import { Box, Color, DisplayPosition, ZIndex } from '@gousto-internal/citrus-react'

import css from './Sidebar.css'

type SidebarProps = {
  isOpen: boolean
  onClose: (event: SyntheticEvent) => void
}

export const Sidebar = ({ children, isOpen, onClose }: React.PropsWithChildren<SidebarProps>) => {
  useEffect(() => {
    if (isOpen) {
      window?.document.body.classList.add(css['prevent-scroll'])
    } else {
      window?.document.body.classList.remove(css['prevent-scroll'])
    }
  }, [isOpen])

  return (
    <>
      <Box
        className={`${css.overlay} ${isOpen ? css['overlay-on'] : ''}`}
        data-testid="sidebar-overlay"
        height="100%"
        left="0"
        onClick={onClose}
        position={DisplayPosition.Fixed}
        top="0"
        width="100%"
        zIndex={ZIndex.Overlay}
      />
      <Box
        bg={Color.White}
        data-testid="sidebar-modal"
        boxShadow="none"
        className={`${css.sidebar} ${isOpen ? css['sidebar-open'] : ''}`}
        height="100%"
        position={DisplayPosition.Fixed}
        right="0"
        top="0"
        width={['100%', '50%', '50%', '35%']}
        zIndex={ZIndex.Top}
      >
        {children}
      </Box>
    </>
  )
}
