import React, { useEffect, useState } from 'react'

import { CTA, Modal, ModalHeader, ModalBody } from 'goustouicomponents'
import PropTypes from 'prop-types'

import Link from 'components/Link'
import configRoutes from 'config/routes'
import { redirect } from 'utils/window'

import css from './CheckoutErrorModal.css'

interface Props {
  shouldShow: boolean
  customMessage?: string
  isAuthenticated?: boolean
}

export const CheckoutErrorModal = ({ shouldShow, customMessage, isAuthenticated }: Props) => {
  const [openModal, setOpenModal] = useState(shouldShow)

  useEffect(() => {
    setOpenModal(shouldShow)
  }, [shouldShow])

  const onClick = () => {
    setOpenModal(false)
  }

  const onClickReloadMenu = () => {
    setOpenModal(false)
    redirect(configRoutes.client.menu)
  }

  const errorMessageLoggedInUsers =
    'There was an issue when confirming your order. Head to your account to check your upcoming deliveries.'
  const errorMessageNotLoggedInUsers =
    'There was an issue when confirming your order. Please try again.'

  const defaultErrorMessage = isAuthenticated
    ? errorMessageLoggedInUsers
    : errorMessageNotLoggedInUsers
  const errorMessage = customMessage || defaultErrorMessage

  return (
    <Modal
      isOpen={openModal}
      variant="floating"
      name="error-modal"
      description="checkout error modal"
      handleClose={isAuthenticated ? onClick : onClickReloadMenu}
      withOverlay
    >
      <ModalHeader withSeparator align="left">
        Uh oh, something went wrong
      </ModalHeader>
      <ModalBody>
        <p className={css.modalText}>{errorMessage}</p>
        {isAuthenticated && (
          <Link to={configRoutes.client.myDeliveries}>
            <CTA size="medium" variant="primary" isFullWidth onClick={() => {}}>
              View deliveries
            </CTA>
          </Link>
        )}
        {!isAuthenticated && (
          <CTA
            size="medium"
            variant="primary"
            isFullWidth
            onClick={() => {
              onClickReloadMenu()
            }}
          >
            Try Again
          </CTA>
        )}
      </ModalBody>
    </Modal>
  )
}

CheckoutErrorModal.propTypes = {
  shouldShow: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
}
