import { datadogLogs } from '@datadog/browser-logs'
import useSWR from 'swr'

import { menuFetcherV3, MenuRequestParamsV3 } from '@library/menu-service'

const MENU_RECOMMENDED_CATEGORY_UUID = '97270056-cd65-11e8-a2d2-067a72dd5dba'

// formats iso date 2024-09-06 00:00:00 to 2024-09-06
const formatDate = (isoDate: string): string => isoDate.split(' ')[0]

export const useRecommendedRecipes = (requestParams: MenuRequestParamsV3) => {
  const { data, error } = useSWR(
    {
      numPortions: requestParams.numPortions,
      deliveryDate: formatDate(requestParams.deliveryDate || ''),
      sessionId: requestParams.sessionId,
    },
    menuFetcherV3,
  )

  if (error) {
    datadogLogs.logger.error(`Error fetching menu data in first box order ${JSON.stringify(error)}`)

    return {
      recipes: [],
    }
  }

  const hasRecomendedRecipes = data?.categories && data.categories[MENU_RECOMMENDED_CATEGORY_UUID]

  if (!hasRecomendedRecipes) {
    return {
      recipes: [],
    }
  }

  const categorizedRecipes = data.categories[MENU_RECOMMENDED_CATEGORY_UUID].recipes

  const topFiveRecipes = categorizedRecipes.slice(0, 5).map((recipe) => data.recipes[recipe.id])

  const recipeCards = topFiveRecipes.map((recipe) => ({
    tag: recipe.food_brand?.name || '',
    images: recipe.images,
    dietaryClaims: recipe.dietary_claims.map((claim) => ({ slug: claim.slug })),
    title: recipe.name,
    calories: recipe.nutritional_information.per_portion.energy_kcal,
    prepTimes: recipe.prep_time,
  }))

  return {
    recipes: recipeCards,
  }
}
