import React, { useCallback } from 'react'

import {
  Box,
  Button,
  ButtonColorVariant,
  ButtonSize,
  ButtonVariant,
  Display,
  DisplayPosition,
  FlexDirection,
  JustifyContent,
  Overflow,
} from '@gousto-internal/citrus-react'
import { browserCookies } from '@gousto-internal/storage-consent-web-package'
import { browserHistory } from 'react-router'

import { useRecommendedRecipes } from '../useRecommendedRecipes'
import { RecipeCard } from './RecipeCard'
import { useCarouselNav } from './useCarouselNav'

type Order = {
  id: string
  deliveryDate: string
  box: {
    numPortions: string
  }
  prices: {
    isDeliveryFree: boolean
    percentageOff: string
  }
}

type Props = {
  order: Order
}

const EMPTY_UUID = '00000000-0000-0000-0000-000000000000'

export const Carousel = ({ order }: Props) => {
  const { emblaRef, prevBtnDisabled, nextBtnDisabled, onPrevButtonClick, onNextButtonClick } =
    useCarouselNav()

  const handleCardClick = useCallback(() => {
    browserHistory.push(`/menu/${order?.id}`)
  }, [order?.id])

  const [consented, sessionId] = browserCookies.getString('gousto_session_id')
  const { recipes } = useRecommendedRecipes({
    deliveryDate: order?.deliveryDate,
    numPortions: parseInt(order?.box?.numPortions, 10),
    sessionId: consented ? sessionId || '' : EMPTY_UUID,
  })

  return (
    <Box
      data-testid="carousel"
      display={Display.Flex}
      flexDirection={FlexDirection.Column}
      position={DisplayPosition.Relative}
    >
      <Box className="embla__viewport" ref={emblaRef} width="100%" overflowX={Overflow.Hidden}>
        <Box className="embla__container" display={Display.Flex} width="100%" gap={2}>
          {recipes?.map((card, index) => (
            <Box
              // eslint-disable-next-line react/no-array-index-key
              key={`${card.title}-${index}`}
              className="embla__slide"
              flexGrow={0}
              flexShrink={0}
              width={['228px', '350px']}
              height="310px"
              role="button"
              onClick={handleCardClick}
            >
              <RecipeCard
                tag={card.tag}
                images={card.images}
                dietaryClaims={card.dietaryClaims}
                title={card.title}
                calories={card.calories}
                prepTimes={card.prepTimes}
              />
            </Box>
          ))}
        </Box>
      </Box>
      <Box
        display={Display.Flex}
        width="100%"
        position={DisplayPosition.Absolute}
        top="50%"
        justifyContent={JustifyContent.SpaceBetween}
        style={{
          transform: 'translateY(-50%)',
        }}
        paddingH={3}
      >
        <Button
          aria-label="Previous slide"
          disabled={prevBtnDisabled}
          className="embla__prev"
          colorVariant={ButtonColorVariant.Secondary}
          icon="arrow_back"
          onClick={onPrevButtonClick}
          size={ButtonSize.Large}
          style={{ borderRadius: '50%' }}
          variant={ButtonVariant.Icon}
        />
        <Button
          aria-label="Next slide"
          disabled={nextBtnDisabled}
          className="embla__next"
          colorVariant={ButtonColorVariant.Secondary}
          icon="arrow_right"
          onClick={onNextButtonClick}
          size={ButtonSize.Large}
          style={{ borderRadius: '50%' }}
          variant={ButtonVariant.Icon}
        />
      </Box>
    </Box>
  )
}
