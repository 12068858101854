import { useEffect } from 'react'

import actions from 'actions'
import { useDispatch, useSelector } from 'react-redux'

import { boxSummaryDeliveryDaysLoad } from 'actions/boxSummary'
import routesConfig from 'config/routes'
import { checkoutCreatePreviewOrder } from 'routes/Checkout/checkoutActions'
import { useBasket } from 'routes/Menu/domains/basket'
import { loadMenuServiceDataIfDeepLinked } from 'routes/Menu/fetchData/menuService'
import logger from 'utils/logger'
import { getPreviewOrderErrorName } from 'utils/order'

import {
  getBasketOrderPreviewChangePending,
  getDeliveryTimeAndDate,
  getPreviewOrderError,
  getPreviewOrderId,
} from './checkoutSelectors'

export const useGeneratePreviewOrder = () => {
  const dispatch = useDispatch()
  const { addRecipe } = useBasket()
  const { deliveryTime } = useSelector(getDeliveryTimeAndDate)
  const previewOrderError = useSelector(getPreviewOrderError)
  const previewOrderId = useSelector(getPreviewOrderId)

  useEffect(() => {
    const fetchData = async () => {
      // defensive code to ensure menu load days works below for deeplinks
      await dispatch(loadMenuServiceDataIfDeepLinked(false, addRecipe))
      if (!deliveryTime) {
        await dispatch(boxSummaryDeliveryDaysLoad())
      }
      if (!previewOrderId && !previewOrderError) {
        try {
          await dispatch(checkoutCreatePreviewOrder())
        } catch (e) {
          // error is handled in useRedirectOnError
        }
      }
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { previewOrderError, previewOrderId }
}

export const useRedirectOnError = () => {
  /**
   * This hook is used to redirect to the menu page if there is an error in the checkout flow.
   * Error could be an order item being out of stock, an expired basket, or an undefinded error.
   */
  const dispatch = useDispatch()
  const { previewOrderError } = useGeneratePreviewOrder()
  const pending = useSelector(getBasketOrderPreviewChangePending)
  const errorName = getPreviewOrderErrorName(previewOrderError)

  useEffect(() => {
    if (!pending && previewOrderError) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      logger.warning(
        `checkoutHooks/useGeneratePreviewOrder failed to create preview order ID successfully, with error: ${previewOrderError}`,
      )
      dispatch(
        actions.redirect(`${routesConfig.client.menu}?from=newcheckout&error=${errorName}`, true),
      )
    }
  }, [previewOrderError, dispatch, errorName, pending])
}
