import React, { useState } from 'react'

import {
  Body2,
  Box,
  Heading4,
  Join,
  Space,
  Color,
  Icon,
  IconVariant,
  withCitrusTheme,
  AlignItems,
  JustifyContent,
} from '@gousto-internal/citrus-react'
import moment from 'moment'

import OrderSummary from '../../../containers/welcome/OrderSummary'

type YourOrderSummaryProps = {
  deliveryDate: string
  cutOffDate: string
  discountSofteningFreeDeliveryValue: string
}

type YourOrderSummaryHeaderProps = {
  deliveryDate: string
}

type YourOrderSummarySubHeaderProps = {
  cutOffDate: string
}

export const formattedDeliveryDate = (deliveryDate: string) =>
  moment(deliveryDate, 'YYYY-MM-DD').format('dddd, MMM D')

export const formattedCutOoffDate = (cutOffDate: string) =>
  moment(cutOffDate).format('dddd, Do of MMMM')

const CenteredAndClickableBox = withCitrusTheme(Box, () => ({
  margin: 'auto',
  cursor: 'pointer',
}))

const YourOrderSummaryHeader = ({ deliveryDate }: YourOrderSummaryHeaderProps) => (
  <Box>
    <Heading4>Your Order Summary</Heading4>
    <Body2>Arrives {formattedDeliveryDate(deliveryDate)}</Body2>
  </Box>
)

const YourOrderSummarySubHeader = ({ cutOffDate }: YourOrderSummarySubHeaderProps) => (
  <Box>
    <Heading4>Order details</Heading4>
    <Body2>
      You can make changes to this order from your account until {formattedCutOoffDate(cutOffDate)}.
    </Body2>
  </Box>
)

export const YourOrderSummary = ({
  deliveryDate,
  cutOffDate,
  discountSofteningFreeDeliveryValue,
}: YourOrderSummaryProps) => {
  const [isOrderSummaryExpanded, setOrderSummaryExpanded] = useState(false)

  const handleExpandClick = () => {
    setOrderSummaryExpanded(!isOrderSummaryExpanded)
  }

  const renderTabletAndDesktopYourOrderSummary = () => (
    <Box display={['none', 'none', 'block', 'block']}>
      <Join with={<Space size={6} />}>
        <YourOrderSummaryHeader deliveryDate={deliveryDate} />
        <YourOrderSummarySubHeader cutOffDate={cutOffDate} />
      </Join>
      <Box>
        {/* Using the props based on the existing implementation
          Which makes OrderSummary say it is missing the other props
          */}
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <OrderSummary discountSofteningFreeDeliveryValue={discountSofteningFreeDeliveryValue} />
      </Box>
    </Box>
  )

  const renderMobileYourOrderSummary = () => (
    <>
      <Box
        display={['flex', 'flex', 'none', 'none']}
        alignItems={AlignItems.Center}
        justifyContent={JustifyContent.SpaceBetween}
      >
        <YourOrderSummaryHeader deliveryDate={deliveryDate} />
        <Box>
          <CenteredAndClickableBox onClick={handleExpandClick}>
            <Icon
              name={!isOrderSummaryExpanded ? 'chevron_down' : 'chevron_up'}
              variant={IconVariant.Actionable}
              size={6}
            />
          </CenteredAndClickableBox>
        </Box>
      </Box>
      {isOrderSummaryExpanded ? (
        <Box>
          <YourOrderSummarySubHeader cutOffDate={cutOffDate} />

          <Box>
            {/* Using the props based on the existing implementation
          Which makes OrderSummary say it is missing the other props
          */}
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <OrderSummary
              discountSofteningFreeDeliveryValue={discountSofteningFreeDeliveryValue}
              orderSummaryCollapsed={false}
            />
          </Box>
        </Box>
      ) : null}
    </>
  )

  return (
    <Box
      paddingH={[3, 5, 8, 8]}
      paddingV={[3, 5, 8, 8]}
      paddingRight={[3, 5, 8, 8]}
      bg={Color.White}
    >
      {renderTabletAndDesktopYourOrderSummary()}
      {renderMobileYourOrderSummary()}
    </Box>
  )
}
