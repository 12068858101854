// label wrapper takes in a recipe, and maps out all the labels based on tag
import Immutable from 'immutable'
import React from 'react'

import { useRecipeBrandHook } from '../../model/context/useRecipeBrand'
import {
  Box,
  Display,
  withCitrusTheme,
  Space,
  DisplayPosition,
  Color,
  AlignItems,
  useTheme,
} from '@gousto-internal/citrus-react'
import { Label } from './Label'
import { getBrandLabelColors } from './getBrandLabelColors'
import { Recipe } from '../../model/recipe'
import { formatInvalidDietaryPreferences } from '../../utils/formatInvalidDietaryPreferences'
import { DietaryWarningTag } from '../DietaryWarningTag/DietaryWarningTag'

type LabelWrapperProps = {
  isRecipeOutOfStock: (recipeId: string) => boolean
  recipe: Recipe
  dietaryPreferences: Immutable.List<string>
  isMenuPersonalisationEnabled: boolean
  isMenuPreferencesWebEnabled: boolean
}

const LabelWrapperBox = withCitrusTheme(Box, (theme) => ({
  display: Display.Flex,
  borderColor: theme.tokens.colors.White,
  borderWidth: 1.5,
  alignItems: AlignItems.Center,
  position: DisplayPosition.Absolute,
  top: 0,
  left: 0,
  transform: 'translateY(calc(-50% - 1rem))',
  zIndex: 12,
}))

export const LabelWrapper = ({
  isRecipeOutOfStock,
  recipe,
  dietaryPreferences,
  isMenuPersonalisationEnabled,
  isMenuPreferencesWebEnabled,
}: LabelWrapperProps) => {
  const { theme } = useTheme()
  const useRecipeBrand = useRecipeBrandHook()
  const { useTag } = useRecipeBrand()
  const brandTag = useTag(recipe.tagline ?? null)
  const recipeTag = useTag(recipe.availability ?? null)
  const { id: recipeId, isFineDineIn, invalidPreference } = recipe
  const isOutOfStock = isRecipeOutOfStock(recipeId)
  const dietaryWarnings =
    invalidPreference || formatInvalidDietaryPreferences(recipe, dietaryPreferences)

  if (isOutOfStock) {
    const bgColor = isFineDineIn
      ? theme.tokens.colors.ColdGrey_900
      : theme.tokens.colors.ColdGrey_800

    return (
      <LabelWrapperBox data-testid="sold-out-tag">
        <Label text="Sold Out" textColor={Color.White} backgroundColor={bgColor} />
      </LabelWrapperBox>
    )
  }

  if ((isMenuPersonalisationEnabled || isMenuPreferencesWebEnabled) && dietaryWarnings) {
    return (
      <LabelWrapperBox>
        <DietaryWarningTag text={dietaryWarnings} />
      </LabelWrapperBox>
    )
  }

  //if no tags, return
  if (!brandTag && !recipeTag) {
    return null
  }

  let brandTheme, brandText, recipeTheme, recipeText

  if (brandTag) {
    brandTheme = getBrandLabelColors(brandTag, theme, isFineDineIn)
    brandText = brandTag.text
  }

  if (recipeTag) {
    recipeTheme = recipeTag.theme
    recipeText = recipeTag.text
  }

  return (
    <LabelWrapperBox>
      {recipeText && recipeTheme && (
        <>
          <Label
            text={recipeText}
            backgroundColor={recipeTheme?.backgroundColor}
            textColor={recipeTheme?.color}
          />
          <Space size={1} direction="horizontal" />
        </>
      )}

      {brandText && brandTheme && (
        <Label
          text={brandText}
          backgroundColor={brandTheme?.backgroundColor}
          textColor={brandTheme?.color}
        />
      )}
    </LabelWrapperBox>
  )
}
