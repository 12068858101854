import React from 'react'

export const onEnter =
  (callback: (event: React.KeyboardEvent) => void) => (event: React.KeyboardEvent) =>
    event.keyCode === 13 && callback(event)

export const onSpaceOrEnter =
  (callback: (event: React.KeyboardEvent) => void) => (event: React.KeyboardEvent) => {
    if (event.code === 'Space' || event.code === 'Enter') {
      event.preventDefault()
      callback(event)
    }
  }
