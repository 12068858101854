import React from 'react'

import { Body2, Box, Display, Heading5, Space } from '@gousto-internal/citrus-react'

import { EquipmentPreference } from '@library/menu-service/queries/menuApiPreferences/types'

import { SelectablePill } from './SelectablePill'

type EquipmentPreferencesProps = {
  equipmentPreferences: EquipmentPreference[]
  onChange: (selectedEquipmentPreferences: EquipmentPreference[]) => void
}

export const EquipmentPreferences = ({
  equipmentPreferences,
  onChange,
}: EquipmentPreferencesProps) => {
  const handleChange = (selectedEquipment: EquipmentPreference) => {
    const updatedEquipmentPreferences = equipmentPreferences.map((equipment) =>
      equipment.id === selectedEquipment.id ? selectedEquipment : equipment,
    )
    onChange(updatedEquipmentPreferences)
  }

  return (
    <Box paddingV={2}>
      <Heading5>Kitchen equipment</Heading5>
      <Body2>Do you have the following equipment?</Body2>
      <Space size={3} direction="vertical" />
      {equipmentPreferences.map((preference) => (
        <Box display={Display.InlineBlock} key={preference.id} paddingBottom={3} paddingRight={3}>
          <SelectablePill
            key={preference.id}
            onChange={(selected) => handleChange({ ...preference, selected })}
            selected={preference.selected}
            text={preference.name}
          />
        </Box>
      ))}
    </Box>
  )
}
