import { useCallback, useEffect, useState } from 'react'

import useEmblaCarousel, { UseEmblaCarouselType } from 'embla-carousel-react'

type Props = {
  emblaRef: UseEmblaCarouselType[0]
  prevBtnDisabled: boolean
  nextBtnDisabled: boolean
  onPrevButtonClick: () => void
  onNextButtonClick: () => void
}

export const useCarouselNav = (): Props => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: false, slidesToScroll: 'auto' })

  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true)
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true)

  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) {
      return
    }

    emblaApi.scrollPrev()
  }, [emblaApi])

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) {
      return
    }

    emblaApi.scrollNext()
  }, [emblaApi])

  const onSelect = useCallback((inputEmblaApi: UseEmblaCarouselType[1]) => {
    setPrevBtnDisabled(!inputEmblaApi?.canScrollPrev())
    setNextBtnDisabled(!inputEmblaApi?.canScrollNext())
  }, [])

  useEffect(() => {
    if (!emblaApi) {
      return
    }

    onSelect(emblaApi)

    emblaApi.on('reInit', onSelect).on('select', onSelect)
  }, [emblaApi, onSelect])

  return {
    emblaRef,
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  }
}
