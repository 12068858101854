/* eslint sort-keys: "error" */
export const actionTypes = {
  AFFILIATE_SOURCE_SET: 'AFFILIATE_SOURCE_SET',
  APPLE_PAY_ERRORS_CLEAR: 'APPLE_PAY_ERRORS_CLEAR',
  APPLE_PAY_MERCHANT_VALIDATION_ERROR: 'APPLE_PAY_MERCHANT_VALIDATION_ERROR',
  APPLE_PAY_SESSION_FAILED: 'APPLE_PAY_SESSION_FAILED',
  APPLE_PAY_STATUS_FETCH_ERROR: 'APPLE_PAY_STATUS_FETCH_ERROR',
  APPLE_PAY_TOKEN_CONVERSION_ERROR: 'APPLE_PAY_TOKEN_CONVERSION_ERROR',
  APP_BANNER_DISMISSED: 'APP_BANNER_DISMISSED',
  AUTH_PASSWORD_RESET: 'AUTH_PASSWORD_RESET',
  AVAILABLE_BOX_SIZE_RECEIVED: 'AVAILABLE_BOX_SIZE_RECEIVED',
  AWIN_CLICK_CHECKSUM_SET: 'AWIN_CLICK_CHECKSUM_SET',

  BASKET_ADDRESS_CHANGE: 'BASKET_ADDRESS_CHANGE',
  BASKET_CHECKOUT: 'BASKET_CHECKOUT',
  BASKET_CHECKOUT_CLICKED: 'BASKET_CHECKOUT_CLICKED',
  BASKET_CHECKOUT_PROCEED: 'BASKET_CHECKOUT_PROCEED',
  BASKET_CHOSEN_ADDRESS_CHANGE: 'BASKET_CHOSEN_ADDRESS_CHANGE',
  BASKET_CURRENT_MENU_ID_CHANGE: 'BASKET_CURRENT_MENU_ID_CHANGE',
  BASKET_DATE_CHANGE: 'BASKET_DATE_CHANGE',
  BASKET_DELIVERYDAY_CHANGE: 'BASKET_DELIVERYDAY_CHANGE',
  BASKET_ELIGIBLE_TRACK: 'BASKET_ELIGIBLE_TRACK',
  BASKET_GIFT_ADD: 'BASKET_GIFT_ADD',
  BASKET_ID_CHANGE: 'BASKET_ID_CHANGE',
  BASKET_IS_PORTION_SIZE_VIEW_VISIBLE: 'BASKET_IS_PORTION_SIZE_VIEW_VISIBLE',
  BASKET_IS_SHOW_DETAILS_VIEW_FIRST: 'BASKET_IS_SHOW_DETAILS_VIEW_FIRST',
  BASKET_LIMIT_REACHED: 'BASKET_LIMIT_REACHED',
  BASKET_NUM_PEOPLE_CHANGE: 'BASKET_NUM_PEOPLE_CHANGE',
  BASKET_NUM_PORTION_CHANGE: 'BASKET_NUM_PORTION_CHANGE',
  BASKET_ORDER_DETAILS_LOADED: 'BASKET_ORDER_DETAILS_LOADED',
  BASKET_ORDER_LOADED: 'BASKET_ORDER_LOADED',
  BASKET_POSTCODE_CHANGE: 'BASKET_POSTCODE_CHANGE',
  BASKET_POSTCODE_PENDING: 'BASKET_POSTCODE_PENDING',
  BASKET_PREVIEW_ORDER_CHANGE: 'BASKET_PREVIEW_ORDER_CHANGE',
  BASKET_PRODUCT_ADD: 'BASKET_PRODUCT_ADD',
  BASKET_PRODUCT_REMOVE: 'BASKET_PRODUCT_REMOVE',
  BASKET_PRODUCT_TRACKING: 'BASKET_PRODUCT_TRACKING',
  BASKET_PROMO_CODE_APPLIED_CHANGE: 'BASKET_PROMO_CODE_APPLIED_CHANGE',
  BASKET_PROMO_CODE_CHANGE: 'BASKET_PROMO_CODE_CHANGE',
  BASKET_PROMO_CODE_URL_CHANGE: 'BASKET_PROMO_CODE_URL_CHANGE',
  BASKET_RECIPES_INITIALISE: 'BASKET_RECIPES_INITIALISE',
  BASKET_RECIPE_ADD: 'BASKET_RECIPE_ADD',
  BASKET_RECIPE_REMOVE: 'BASKET_RECIPE_REMOVE',
  BASKET_RESET: 'BASKET_RESET',
  BASKET_SELECT_DELIVERY_SLOT: 'BASKET_SELECT_DELIVERY_SLOT',
  BASKET_SELECT_POSTCODE: 'BASKET_SELECT_POSTCODE',
  BASKET_SET_SUBSCRIPTION_OPTION: 'BASKET_SET_SUBSCRIPTION_OPTION',
  BASKET_SIGNUP_COLLECTION_RECEIVE: 'BASKET_SIGNUP_COLLECTION_RECEIVE',
  BASKET_SLOT_CHANGE: 'BASKET_SLOT_CHANGE',
  BASKET_STEPS_ORDER_RECEIVE: 'BASKET_STEPS_ORDER_RECEIVE',
  BASKET_TARIFF_CHANGE: 'BASKET_TARIFF_CHANGE',
  BOXSUMMARY_DELIVERY_DAYS_RECEIVE: 'BOXSUMMARY_DELIVERY_DAYS_RECEIVE',
  BOXSUMMARY_DELIVERY_DAYS_RECEIVE_ERROR: 'BOXSUMMARY_DELIVERY_DAYS_RECEIVE_ERROR',
  BOXSUMMARY_VISIBILITY_CHANGE: 'BOXSUMMARY_VISIBILITY_CHANGE',
  BOX_SIZE_BREAKDOWN_TRACKING: 'BOX_SIZE_BREAKDOWN_TRACKING',
  BOX_SIZE_CHANGED_TRACKING: 'BOX_SIZE_CHANGED_TRACKING',
  BROWSER_SET_USER_AGENT: 'BROWSER_SET_USER_AGENT',
  BROWSER_TYPE_CHANGE: 'BROWSER_TYPE_CHANGE',

  CANCELLED_ALL_BOXES_MODAL_VISIBILITY_CHANGE: 'CANCELLED_ALL_BOXES_MODAL_VISIBILITY_CHANGE',
  CANCELLED_ORDER_RESTORED: 'CANCELLED_ORDER_RESTORED',
  CANCEL_MULTIPLE_BOXES_END: 'CANCEL_MULTIPLE_BOXES_END',
  CANCEL_MULTIPLE_BOXES_ERROR: 'CANCEL_MULTIPLE_BOXES_ERROR',
  CANCEL_MULTIPLE_BOXES_START: 'CANCEL_MULTIPLE_BOXES_START',
  CANCEL_MULTIPLE_BOXES_SUCCESS: 'CANCEL_MULTIPLE_BOXES_SUCCESS',
  CARD_TOKENIZATION_FAILED: 'CARD_TOKENIZATION_FAILED',
  CHECKOUT_ADDRESSES_RECEIVE: 'CHECKOUT_ADDRESSES_RECEIVE',
  CHECKOUT_CARD_SUBMIT: 'CHECKOUT_CARD_SUBMIT',
  CHECKOUT_CARD_TOKENIZATION_FAILED: 'CHECKOUT_CARD_TOKENIZATION_FAILED',
  CHECKOUT_CARD_TOKENIZATION_SUCCEEDED: 'CHECKOUT_CARD_TOKENIZATION_SUCCEEDED',
  CHECKOUT_CONTINUE_TO_PAYMENT: 'CHECKOUT_CONTINUE_TO_PAYMENT',
  CHECKOUT_ERRORS_CLEAR: 'CHECKOUT_ERRORS_CLEAR',
  CHECKOUT_ERROR_DUPLICATE: 'CHECKOUT_ERROR_DUPLICATE',
  CHECKOUT_LOAD_APPLE_PAY_ENABLED_STATUS: 'CHECKOUT_LOAD_APPLE_PAY_ENABLED_STATUS',
  CHECKOUT_ORDER_FAILED: 'CHECKOUT_ORDER_FAILED',
  CHECKOUT_ORDER_PLACED: 'CHECKOUT_ORDER_PLACED',
  CHECKOUT_ORDER_PLACE_ATTEMPT: 'CHECKOUT_ORDER_PLACE_ATTEMPT',
  CHECKOUT_ORDER_PLACE_ATTEMPT_FAILED: 'CHECKOUT_ORDER_PLACE_ATTEMPT_FAILED',
  CHECKOUT_ORDER_PLACE_ATTEMPT_SUCCEEDED: 'CHECKOUT_ORDER_PLACE_ATTEMPT_SUCCEEDED',
  CHECKOUT_PASSWORD_VALIDATION_RULES_SET: 'CHECKOUT_PASSWORD_VALIDATION_RULES_SET',
  CHECKOUT_PAYMENT: 'CHECKOUT_PAYMENT',
  CHECKOUT_PAYPAL_ERRORS_CLEAR: 'CHECKOUT_PAYPAL_ERRORS_CLEAR',
  CHECKOUT_PAYPAL_ERRORS_REPORTED: 'CHECKOUT_PAYPAL_ERRORS_REPORTED',
  CHECKOUT_SET_DELIVERY_ADDRESS: 'CHECKOUT_SET_DELIVERY_ADDRESS',
  CHECKOUT_SET_DELIVERY_INSTRUCTION: 'CHECKOUT_SET_DELIVERY_INSTRUCTION',
  CHECKOUT_SET_EMAIL: 'CHECKOUT_SET_EMAIL',
  CHECKOUT_SET_GOUSTO_REF: 'CHECKOUT_SET_GOUSTO_REF',
  CHECKOUT_SET_IS_COMPETITIONS_AND_SURVEYS_ENABLED:
    'CHECKOUT_SET_IS_COMPETITIONS_AND_SURVEYS_ENABLED',
  CHECKOUT_SET_IS_EMAIL_MARKETING_ENABLED: 'CHECKOUT_SET_IS_EMAIL_MARKETING_ENABLED',
  CHECKOUT_SET_NAME: 'CHECKOUT_SET_NAME',
  CHECKOUT_SET_PHONE: 'CHECKOUT_SET_PHONE',
  CHECKOUT_SIGNUP: 'CHECKOUT_SIGNUP',
  CHECKOUT_SIGNUP_LOGIN: 'CHECKOUT_SIGNUP_LOGIN',
  CHECKOUT_SIGNUP_START_RECAPTCHA_EXECUTION: 'CHECKOUT_SIGNUP_START_RECAPTCHA_EXECUTION',
  CHECKOUT_SIGNUP_SUCCESS: 'CHECKOUT_SIGNUP_SUCCESS',
  CHECKOUT_URGENCY_SET_CHECKOUT_STARTED_TIME: 'CHECKOUT_URGENCY_SET_CHECKOUT_STARTED_TIME',
  CHOOSE_PLAN_CONTINUE: 'CHOOSE_PLAN_CONTINUE',
  CLICK_PAY_WITH_APPLE_PAY: 'CLICK_PAY_WITH_APPLE_PAY',
  COLLECTION_NAV_SHOWN: 'COLLECTION_NAV_SHOWN',
  CUSTOMER_CANCELLED_THREEDSECURE: 'CUSTOMER_CANCELLED_THREEDSECURE',
  CUSTOMER_NOT_ENROLLED_IN_THREEDSECURE: 'CUSTOMER_NOT_ENROLLED_IN_THREEDSECURE',

  DELIVERY_DAY_SELECTION_EDITED: 'DELIVERY_DAY_SELECTION_EDITED',
  DELIVERY_PREFERENCE_MODAL_CLOSED: 'DELIVERY_PREFERENCE_MODAL_CLOSED',
  DELIVERY_PREFERENCE_MODAL_VIEWED: 'DELIVERY_PREFERENCE_MODAL_VIEWED',
  DELIVERY_PREFERENCE_SELECTED: 'DELIVERY_PREFERENCE_SELECTED',
  DELIVERY_SLOT_DROPDOWN_OPEN: 'DELIVERY_SLOT_DROPDOWN_OPEN',
  DELIVERY_SLOT_SELECTION_EDITED: 'DELIVERY_SLOT_SELECTION_EDITED',

  ERROR: 'ERROR',
  EXPIRED_BILLING_MODAL_VISIBILITY_CHANGE: 'EXPIRED_BILLING_MODAL_VISIBILITY_CHANGE',
  EXPRESS_CHECKOUT_START_RECAPTCHA_EXECUTION: 'EXPRESS_CHECKOUT_START_RECAPTCHA_EXECUTION',

  FEATURES_SET: 'FEATURES_SET',
  FEEDBACK_COUNT_RECEIVED: 'FEEDBACK_COUNT_RECEIVED',
  FILTERS_COLLECTION_CHANGE: 'FILTERS_COLLECTION_CHANGE',
  FILTERS_PRODUCT_CATEGORY: 'FILTERS_PRODUCT_CATEGORY',
  FILTERS_RESET: 'FILTERS_RESET',

  GET_HELP_CONTACT_CHANNEL_SELECT: 'GET_HELP_CONTACT_CHANNEL_SELECT',
  GET_HELP_FETCH_INGREDIENT_ISSUES: 'GET_HELP_FETCH_INGREDIENT_ISSUES',
  GET_HELP_INGREDIENTS_ACCEPT_REFUND: 'GET_HELP_INGREDIENTS_ACCEPT_REFUND',
  GET_HELP_INGREDIENT_ISSUES_SELECT: 'GET_HELP_INGREDIENT_ISSUES_SELECT',
  GET_HELP_LOAD_ORDERS_BY_ID: 'GET_HELP_LOAD_ORDERS_BY_ID',
  GET_HELP_ORDER_ISSUE_SELECT: 'GET_HELP_ORDER_ISSUE_SELECT',
  GET_HELP_STORE_INGREDIENT_ISSUE_REASONS: 'GET_HELP_STORE_INGREDIENT_ISSUE_REASONS',
  GET_HELP_STORE_ORDER_ID: 'GET_HELP_STORE_ORDER_ID',
  GET_HELP_STORE_SELECTED_INGREDIENTS: 'GET_HELP_STORE_SELECTED_INGREDIENTS',
  GET_HELP_STORE_SELECTED_INGREDIENT_ISSUE: 'GET_HELP_STORE_SELECTED_INGREDIENT_ISSUE',
  GET_HELP_VALIDATE_INGREDIENTS: 'GET_HELP_VALIDATE_INGREDIENTS',
  GET_HELP_VALIDATE_ORDER: 'GET_HELP_VALIDATE_ORDER',
  GOOGLE_PAY_ERRORS_CLEAR: 'GOOGLE_PAY_ERRORS_CLEAR',
  GOOGLE_PAY_STORE_3DS: 'GOOGLE_PAY_STORE_3DS',
  GOOGLE_PAY_TOKEN_PARSING_ERROR: 'GOOGLE_PAY_TOKEN_PARSING_ERROR',

  HELP_PRELOGIN_VISIBILITY_CHANGE: 'HELP_PRELOGIN_VISIBILITY_CHANGE',

  IDENTIFY_APPLE_PAY_ELIGIBILITY: 'IDENTIFY_APPLE_PAY_ELIGIBILITY',
  INCREMENT_TUTORIAL_VIEWED: 'INCREMENT_TUTORIAL_VIEWED',

  LOADING_ORDER: 'LOADING_ORDER',
  LOGGER_SET_UUID: 'LOGGER_SET_UUID',
  LOGGING_MANAGER_EVENT_ERROR: 'LOGGING_MANAGER_EVENT_ERROR',
  LOGGING_MANAGER_EVENT_PENDING: 'LOGGING_MANAGER_EVENT_PENDING',
  LOGGING_MANAGER_EVENT_SENT: 'LOGGING_MANAGER_EVENT_SENT',
  LOGIN_ATTEMPT: 'LOGIN_ATTEMPT',
  LOGIN_FAILED: 'LOGIN_FAILED',
  LOGIN_REMEMBER_ME: 'LOGIN_REMEMBER_ME',
  LOGIN_VISIBILITY_CHANGE: 'LOGIN_VISIBILITY_CHANGE',

  MENU_BOX_PRICES_RECEIVE: 'MENU_BOX_PRICES_RECEIVE',
  MENU_BROWSE_CTA_VISIBILITY_CHANGE: 'MENU_BROWSE_CTA_VISIBILITY_CHANGE',
  MENU_COLLECTIONS_RECEIVE: 'MENU_COLLECTIONS_RECEIVE',
  MENU_CUTOFF_UNTIL_RECEIVE: 'MENU_CUTOFF_UNTIL_RECEIVE',
  MENU_CUTOFF_UNTIL_RECEIVE_V2: 'MENU_CUTOFF_UNTIL_RECEIVE_V2',
  MENU_FETCH_DATA: 'MENU_FETCH_DATA',
  MENU_LOADING_ERROR: 'MENU_LOADING_ERROR',
  MENU_LOAD_COMPLETE: 'MENU_LOAD_COMPLETE',
  MENU_PREFERENCES_VISIBILITY_CHANGE: 'MENU_PREFERENCES_VISIBILITY_CHANGE',
  MENU_RECIPES_RECEIVE_PENDING: 'MENU_RECIPES_RECEIVE_PENDING',
  MENU_RECIPE_DETAIL_VISIBILITY_CHANGE: 'MENU_RECIPE_DETAIL_VISIBILITY_CHANGE',
  MENU_RECIPE_VARIANT_INIT: 'MENU_RECIPE_VARIANT_INIT',
  MENU_RECIPE_VARIANT_SELECTED: 'MENU_RECIPE_VARIANT_SELECTED',
  MENU_SELL_THE_PROPOSITION_TILE_CLOSED: 'MENU_SELL_THE_PROPOSITION_PAGE_CLOSED',
  MENU_SERVICE_DATA_RECEIVED: 'MENU_SERVICE_DATA_RECEIVED',
  MENU_SET_CALCULATED_TIME_TO_USABLE: 'MENU_SET_CALCULATED_TIME_TO_USABLE',
  MYDELIVERIES_ORDERS: 'MYDELIVERIES_ORDERS',

  NETWORK_FAILURE: 'NETWORK_FAILURE',

  OPTIMIZELY_ROLLOUT_LOADING: 'OPTIMIZELY_ROLLOUT_LOADING',
  ORDER_ADDRESS_CHANGE: 'ORDER_ADDRESS_CHANGE',
  ORDER_CANCEL: 'ORDER_CANCEL',
  ORDER_CANCELLED_MODAL_VISIBILITY_CHANGE: 'ORDER_CANCELLED_MODAL_VISIBILITY_CHANGE',
  ORDER_CONFIRMATION_EDITED_TRACKING: 'ORDER_CONFIRMATION_EDITED_TRACKING',
  ORDER_CREATE_TRANSACTIONAL: 'ORDER_CREATE_TRANSACTIONAL',
  ORDER_DELIVERY_DAYS_RECEIVE: 'ORDER_DELIVERY_DAYS_RECEIVE',
  ORDER_RECIPES_CHOSEN: 'ORDER_RECIPES_CHOSEN',
  ORDER_SAVE: 'ORDER_SAVE',
  ORDER_SKIP_RECOVERY_MODAL_VISIBILITY_CHANGE: 'ORDER_SKIP_RECOVERY_MODAL_VISIBILITY_CHANGE',
  ORDER_SKIP_RECOVERY_TRIGGERED: 'ORDER_SKIP_RECOVERY_TRIGGERED',
  ORDER_UPDATE_DELIVERY_DAY_AND_SLOT: 'ORDER_UPDATE_DELIVERY_DAY_AND_SLOT',
  PAGE_CHANGED: 'PAGE_CHANGED',

  PAYMENT_BILLING_ADDRESS_DIFFERENT: 'PAYMENT_BILLING_ADDRESS_DIFFERENT',
  PAYMENT_HIDE_MODAL: 'PAYMENT_HIDE_MODAL',
  PAYMENT_SET_BILLING_ADDRESS: 'PAYMENT_SET_BILLING_ADDRESS',
  PAYMENT_SET_CARD_PAYMENT_TOKEN: 'PAYMENT_SET_CARD_PAYMENT_TOKEN',
  PAYMENT_SET_PAYMENT_METHOD: 'PAYMENT_SET_PAYMENT_METHOD',
  PAYMENT_SET_PAYPAL_CLIENT_TOKEN: 'PAYMENT_SET_PAYPAL_CLIENT_TOKEN',
  PAYMENT_SET_PAYPAL_DEVICE_DATA: 'PAYMENT_SET_PAYPAL_DEVICE_DATA',
  PAYMENT_SET_PAYPAL_NONCE: 'PAYMENT_SET_PAYPAL_NONCE',
  PAYMENT_SHOW_MODAL: 'PAYMENT_SHOW_MODAL',
  PAYPAL_ERROR: 'PAYPAL_ERROR',
  PAYPAL_TOKEN_FETCH_FAILED: 'PAYPAL_TOKEN_FETCH_FAILED',
  PENDING: 'PENDING',
  PERSIST_SIMPLE_HEADER: 'PERSIST_SIMPLE_HEADER',
  PORTION_SIZE_SELECTED_TRACKING: 'PORTION_SIZE_SELECTED_TRACKING',
  PRODUCTS_FILTER_TRACKING: 'PRODUCTS_FILTER_TRACKING',
  PRODUCTS_RECEIVE: 'PRODUCTS_RECEIVE',
  PRODUCTS_STOCK_CHANGE: 'PRODUCTS_STOCK_CHANGE',
  PRODUCT_CATEGORIES_RECEIVE: 'PRODUCT_CATEGORIES_RECEIVE',
  PRODUCT_DETAILS_MODAL_TRACKING: 'PRODUCT_DETAILS_MODAL_TRACKING',
  PROJECTED_ORDER_CANCEL: 'PROJECTED_ORDER_CANCEL',
  PROJECTED_ORDER_RESTORE: 'PROJECTED_ORDER_RESTORE',
  PROMO_AGE_VERIFY: 'PROMO_AGE_VERIFY',
  PROMO_APPLY: 'PROMO_APPLY',
  PROMO_GET: 'PROMO_GET',
  PROMO_MODAL_VISIBILITY_CHANGE: 'PROMO_MODAL_VISIBILITY_CHANGE',
  PROMO_RECEIVE: 'PROMO_RECEIVE',
  PROMO_SET: 'PROMO_SET',
  PROMO_STORE_SAVE_ERROR: 'PROMO_STORE_SAVE_ERROR',
  PS_END_MODAL_VIEWED: 'PS_END_MODAL_VIEWED',
  PS_REASON_CATEGORY_MODAL_VIEWED: 'PS_REASON_CATEGORY_MODAL_VIEWED',
  PS_REASON_CATEGORY_SELECTED: 'PS_REASON_CATEGORY_SELECTED',
  PS_REASON_LIST_MODAL_VIEWED: 'PS_REASON_LIST_MODAL_VIEWED',
  PS_REASON_SELECTED: 'PS_REASON_SELECTED',
  PS_RECOVERY_ATTEMPT_MODAL_VIEWED: 'PS_RECOVERY_ATTEMPT_MODAL_VIEWED',
  PS_START_MODAL_VIEWED: 'PS_START_MODAL_VIEWED',
  PS_SUBSCRIPTION_KEPT_ACTIVE: 'PS_SUBSCRIPTION_KEPT_ACTIVE',
  PS_SUBSCRIPTION_PAUSED: 'PS_SUBSCRIPTION_PAUSED',
  PS_SUBSCRIPTION_PAUSE_ATTEMPT: 'PS_SUBSCRIPTION_PAUSE_ATTEMPT',

  RECIPES_RECEIVE: 'RECIPES_RECEIVE',
  RECIPES_SHOWN: 'RECIPES_SHOWN',
  RECIPE_ADDED: 'RECIPE_ADDED',

  RECIPE_COLLECTION_SELECTED: 'RECIPE_COLLECTION_SELECTED',
  RECIPE_REMOVED: 'RECIPE_REMOVED',
  REFER_FRIEND_LINK_COPIED: 'REFER_FRIEND_LINK_COPIED',
  REFER_FRIEND_LINK_SHARE: 'REFER_FRIEND_LINK_SHARE',
  REFER_FRIEND_SHARE_SHEET_CLOSED: 'REFER_FRIEND_SHARE_SHEET_CLOSED',
  REFER_FRIEND_SHARE_SHEET_OPENED: 'REFER_FRIEND_SHARE_SHEET_OPENED',

  SAVE_APPLE_PAY_CHECKOUT_TOKEN: 'SAVE_APPLE_PAY_CHECKOUT_TOKEN',
  SAVE_APPLE_PAY_DELIVERY_ADDRESS: 'SAVE_APPLE_PAY_DELIVERY_ADDRESS',
  SAVE_APPLE_PAY_DELIVERY_INSTRUCTION: 'SAVE_APPLE_PAY_DELIVERY_INSTRUCTION',
  SAVE_APPLE_PAY_DELIVERY_INSTRUCTION_CUSTOM: 'SAVE_APPLE_PAY_DELIVERY_INSTRUCTION_CUSTOM',
  SAVE_APPLE_PAY_PERSONAL_INFO: 'SAVE_APPLE_PAY_PERSONAL_INFO',
  SAVE_DELIVERY_DETAILS_INSTRUCTION: 'SAVE_DELIVERY_DETAILS_INSTRUCTION',
  SAVE_DELIVERY_DETAILS_INSTRUCTION_CUSTOM: 'SAVE_DELIVERY_DETAILS_INSTRUCTION_CUSTOM',
  SAVE_GOOGLE_PAY_DELIVERY_ADDRESS: 'SAVE_GOOGLE_PAY_DELIVERY_ADDRESS',
  SAVE_GOOGLE_PAY_PERSONAL_INFO: 'SAVE_GOOGLE_PAY_PERSONAL_INFO',
  SAVE_GOOGLE_PAY_TOKEN: 'SAVE_GOOGLE_PAY_TOKEN',
  SERVER_REDIRECT: 'SERVER_REDIRECT',
  SERVER_REPLACE: 'SERVER_REPLACE',
  SET_BASKET_PRODUCTS: 'SET_BASKET_PRODUCTS',
  SET_RIBBON_TRIGGERED: 'SET_RIBBON_TRIGGERED',
  SET_ROKT_DATA: 'SET_ROKT_DATA',
  SET_TAPJOY_DATA: 'SET_TAPJOY_DATA',
  SET_TUTORIAL_VIEWED: 'SET_TUTORIAL_VIEWED',
  SET_TUTORIAL_VISIBLE: 'SET_TUTORIAL_VISIBLE',
  SET_UTM_SOURCE: 'SET_UTM_SOURCE',
  SIGNUP_DISMISS_DISCOUNT_APPLIED_BAR: 'SIGNUP_DISMISS_DISCOUNT_APPLIED_BAR',
  SIGNUP_PAYMENT_FAILED: 'SIGNUP_PAYMENT_FAILED',
  SIGNUP_SET_DIETARY_PREFERENCES: 'SIGNUP_SET_DIETARY_PREFERENCES',
  SIGNUP_SET_IS_DIETARY_CONFIRMED: 'SIGNUP_SET_IS_DIETARY_CONFIRMED',
  SIGNUP_SET_IS_RECIPE_CONFIRMED: 'SIGNUP_SET_IS_RECIPE_CONFIRMED',
  SIGNUP_SET_RECIPE_PREFERENCES: 'SIGNUP_SET_RECIPE_PREFERENCES',
  SIGNUP_SET_SOCIAL_BELONGING_OPTIONS: 'SIGNUP_SET_SOCIAL_BELONGING_OPTIONS',
  SIGNUP_SHOW_DISCOUNT_APPLIED_BAR: 'SIGNUP_SHOW_DISCOUNT_APPLIED_BAR',
  SIGNUP_STEPS_RECEIVE: 'SIGNUP_STEPS_RECEIVE',
  SIGNUP_STEP_SET: 'SIGNUP_STEP_SET',
  SIGNUP_TRACKING: 'SIGNUP_TRACKING',
  SIGNUP_TRACKING_STEP_CHANGE: 'SIGNUP_TRACKING_STEP_CHANGE',
  SUBSCRIPTION_DEACTIVATE: 'SUBSCRIPTION_DEACTIVATE',
  SUBSCRIPTION_LOAD_DATA: 'SUBSCRIPTION_LOAD_DATA',
  SUBSCRIPTION_PAUSE_CANCEL_ORDERS: 'SUBSCRIPTION_PAUSE_CANCEL_ORDERS',
  SUBSCRIPTION_PAUSE_ERROR: 'SUBSCRIPTION_PAUSE_ERROR',
  SUBSCRIPTION_PAUSE_FETCH: 'SUBSCRIPTION_PAUSE_FETCH',
  SUBSCRIPTION_PAUSE_OSR_REASON_SELECTED: 'SUBSCRIPTION_PAUSE_OSR_REASON_SELECTED',
  SUBSCRIPTION_PAUSE_PROMO_APPLY: 'SUBSCRIPTION_PAUSE_PROMO_APPLY',
  SUBSCRIPTION_PAUSE_REASON_LOAD_STEP: 'SUBSCRIPTION_PAUSE_REASON_LOAD_STEP',
  SUBSCRIPTION_PAUSE_REASON_SUBMIT: 'SUBSCRIPTION_PAUSE_REASON_SUBMIT',
  SUBSCRIPTION_PAUSE_SKIP_BOX: 'SUBSCRIPTION_PAUSE_SKIP_BOX',
  SUBSCRIPTION_PAUSE_VISIBILITY_CHANGE: 'SUBSCRIPTION_PAUSE_VISIBILITY_CHANGE',

  TEMP: 'TEMP',
  TEMP_RESET: 'TEMP_RESET',
  TRACKING: 'TRACKING',
  TRACKING_CTA_TO_ALL_RECIPES_CLICKED: 'TRACKING_CTA_TO_ALL_RECIPES_CLICKED',
  TRACKING_UNAVAILABLE_RECIPE_LIST: 'TRACKING_UNAVAILABLE_RECIPE_LIST',
  TRACKING_UNDO_DELIVERY_OPTIONS_CHANGE: 'TRACKING_UNDO_DELIVERY_OPTIONS_CHANGE',
  TRACK_VARIANT_RECIPE_LIST_DISPLAY: 'TRACK_VARIANT_RECIPE_LIST_DISPLAY',
  TUTORIAL_TRACKING: 'TUTORIAL_TRACKING',

  UNSUBSCRIBED_USER: 'UNSUBSCRIBED_USER',
  USER_AGE_VERIFY: 'USER_AGE_VERIFY',
  USER_AUTHENTICATED: 'USER_AUTHENTICATED',
  USER_AUTH_FAILED: 'USER_AUTH_FAILED',
  USER_CLEAR_DATA: 'USER_CLEAR_DATA',
  USER_CREDIT: 'USER_CREDIT',
  USER_IDENTIFIED: 'USER_IDENTIFIED',
  USER_LOAD_ADDRESSES: 'USER_LOAD_ADDRESSES',
  USER_LOAD_DATA: 'USER_LOAD_DATA',
  USER_LOAD_ORDERS: 'USER_LOAD_ORDERS',
  USER_LOAD_ORDERS_WITH_RESTORABLE: 'USER_LOAD_ORDERS_WITH_RESTORABLE',
  USER_LOAD_ORDER_TRACKING: 'USER_LOAD_ORDER_TRACKING',
  USER_LOAD_ORDER_TRACKINGS: 'USER_LOAD_ORDER_TRACKINGS',
  USER_LOAD_PROJECTED_DELIVERIES: 'USER_LOAD_PROJECTED_DELIVERIES',
  USER_LOAD_REFERRAL_DETAILS: 'USER_LOAD_REFERRAL_DETAILS',
  USER_LOAD_REFERRAL_OFFER: 'USER_LOAD_REFERRAL_OFFER',
  USER_LOGGED_IN: 'USER_LOGGED_IN',
  USER_LOGGED_OUT: 'USER_LOGGED_OUT',
  USER_LOGIN: 'USER_LOGIN',
  USER_ORDER_CANCEL_NEXT: 'USER_ORDER_CANCEL_NEXT',
  USER_ORDER_CARD_OPEN_CLOSE: 'USER_ORDER_CARD_OPEN_CLOSE',
  USER_ORDER_EDIT_OPEN_CLOSE: 'USER_ORDER_EDIT_OPEN_CLOSE',
  USER_ORDER_SKIP_NEXT_PROJECTED: 'USER_ORDER_SKIP_NEXT_PROJECTED',
  USER_POST_PAYMENT_METHOD: 'USER_POST_PAYMENT_METHOD',
  USER_PROSPECT: 'USER_PROSPECT',
  USER_REACTIVATE: 'USER_REACTIVATE',
  USER_REMEMBER_ME: 'USER_REMEMBER_ME',
  USER_SHIPPING_ADDRESSES_ERROR: 'USER_SHIPPING_ADDRESSES_ERROR',
  USER_SHIPPING_ADDRESSES_PENDING: 'USER_SHIPPING_ADDRESSES_PENDING',
  USER_SHIPPING_ADDRESSES_RECEIVE: 'USER_SHIPPING_ADDRESSES_RECEIVE',
  USER_SUBSCRIBE: 'USER_SUBSCRIBE',
  USER_UNLOAD_ORDERS: 'USER_UNLOAD_ORDERS',
  USER_UNLOAD_PROJECTED_DELIVERIES: 'USER_UNLOAD_PROJECTED_DELIVERIES',

  VALID_CARD_DETAILS_NOT_PROVIDED: 'VALID_CARD_DETAILS_NOT_PROVIDED',
  VIEW_RECIPE_LIST: 'VIEW_RECIPE_LIST',
  VOID: 'VOID', // to be used when action type is required but there's no actual action to dispatch

  WIZARD_SEEN: 'WIZARD_SEEN',

  __REACT_ROUTER_LOCATION_CHANGE: '@@router/LOCATION_CHANGE', // React router,
} as const
