import React from 'react'

import { onEnter } from 'utils/accessibility'

import { getDocumentHeight, getEllipse } from './helpers'

import css from './Spotlight.css'

const RADIUS = 100
const accuracy = 0.3

type SpotlightProps = {
  x?: number
  y?: number
  onClick?: () => void
}

const Spotlight = ({ x = 0, y = 0, onClick }: SpotlightProps) => {
  const steps = getEllipse({
    originX: x,
    originY: y,
    radius: RADIUS,
    accuracy,
  })

  return (
    <div
      role="button"
      tabIndex={0}
      data-testing="spotlight-overlay"
      className={css.spotlight}
      style={{
        height: getDocumentHeight(),
        clipPath: `polygon(
          0% 0%,
          100% 0%,
          100% ${y}px,
          ${steps}
          100% ${y}px,
          100% 100%,
          0% 100%
        )`,
        WebkitClipPath: `polygon(
          0% 0%,
          100% 0%,
          100% ${y}px,
          ${steps}
          100% ${y}px,
          100% 100%,
          0% 100%
        )`,
      }}
      onClick={onClick}
      onKeyDown={onClick ? onEnter(onClick) : undefined}
    />
  )
}

export { Spotlight, RADIUS, accuracy }
