import React from 'react'

import {
  AlignItems,
  BorderStyle,
  Box,
  Color,
  Display,
  DisplayPosition,
  Strong,
  Text,
  Visible,
  ZIndex,
} from '@gousto-internal/citrus-react'

export const InformationBannerMobile = () => (
  <Visible display={[Display.Block, Display.None]}>
    <Box
      data-testid="menu-information-banner-mobile"
      display={Display.Flex}
      bg={Color.Informative_50}
      borderStyle={BorderStyle.Solid}
      borderColor={Color.Informative_200}
      borderTopWidth={0.5}
      width="100%"
      height="28px"
      paddingH={3}
      alignItems={AlignItems.Center}
      position={DisplayPosition.Fixed}
      zIndex={ZIndex.Sticky}
      bottom="64px"
    >
      <Text size={2} color={Color.ColdGrey_800}>
        <Strong color={Color.Informative_900}>{`Add at least 2 recipes `}</Strong>
        to check out
      </Text>
    </Box>
  </Visible>
)
