import React from 'react'

import { Body1, Box, Color, Heading4, Image, TextAlign } from '@gousto-internal/citrus-react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { DeviceType, useDeviceType } from '@library/dom'

import { menuPreferencesVisibilityChange } from 'actions/menu'
import { incrementTutorialViewed, tutorialTracking } from 'actions/tutorial'
import { Portal } from 'routes/Menu/components/Portal'
import { Step, Tutorial } from 'routes/Menu/components/Tutorial'

import menuPreferencesSpotlightImage from 'media/photos/menu-preferences-spotlight.jpg'

import { useShowJFYTutorial } from '../JustForYouTutorial/useShowJFYTutorial'

const tutorialName = 'menu_preferences'

export const MenuPreferencesTutorial = () => {
  const dispatch = useDispatch()
  const deviceType = useDeviceType()
  const showCFYTutorial = useShowJFYTutorial()

  const tutorialViewed = useSelector(({ tutorial }: RootStateOrAny) =>
    Boolean(tutorial && tutorial.getIn(['viewed', tutorialName])),
  )

  const openSidebar = () => {
    dispatch(menuPreferencesVisibilityChange(true))
  }

  const onCloseTutorial = (step: number) => {
    dispatch(incrementTutorialViewed(tutorialName))
    dispatch(tutorialTracking(tutorialName, step, true))
  }

  const trackStepViewed = (step: number) => {
    dispatch(tutorialTracking(tutorialName, step, false))
  }

  if (tutorialViewed || showCFYTutorial) {
    return null
  }

  return (
    <Portal>
      <Tutorial onClose={onCloseTutorial} trackStepViewed={trackStepViewed}>
        <Step
          buttonText="Set preferences"
          next={openSidebar}
          selector={`[data-testid="menu-preferences-button-${
            deviceType === DeviceType.DESKTOP ? 'desktop' : 'mobile'
          }"]`}
        >
          <Box paddingH={3}>
            <Box maxWidth="380px" paddingBottom={4}>
              <Image alt="Calories education" src={menuPreferencesSpotlightImage} />
            </Box>
            <Heading4 color={Color.Primary_600} textAlign={TextAlign.Center}>
              Personalise your menu
            </Heading4>
            <Body1 textAlign={TextAlign.Center}>
              Set your preferences and we&apos;ll let you know if any recipes are less relevant for
              you.
            </Body1>
          </Box>
        </Step>
      </Tutorial>
    </Portal>
  )
}
