import React, { PureComponent } from 'react'

import { SaveButton } from 'OrderSummary/SaveButton'
import { ProductItem } from 'Product/ProductItem'
import classnames from 'classnames'
import Immutable from 'immutable'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'

import { Icon } from 'components/Icon'
import { Item } from 'components/Item'
import { Receipt } from 'components/Receipt'
import { UserCreditMessage } from 'components/UserCreditMessage'
import { deliveryPriceConfig } from 'config/deliveryPrice'
import { isNotAGift } from 'utils/products'

import css from './OrderSummary.css'

const propTypes = {
  prices: ImmutablePropTypes.map,
  deliveryDate: PropTypes.string.isRequired,
  deliverySlot: PropTypes.instanceOf(Immutable.Map),
  giftItems: PropTypes.instanceOf(Immutable.Map),
  numPortions: PropTypes.number.isRequired,
  numRecipes: PropTypes.number.isRequired,
  productItems: PropTypes.instanceOf(Immutable.Map),
  products: ImmutablePropTypes.map.isRequired,
  recipeItems: PropTypes.instanceOf(Immutable.Map),
  recipes: ImmutablePropTypes.map.isRequired,
  removeProduct: PropTypes.func,
  shippingAddress: ImmutablePropTypes.map,
  saveError: PropTypes.bool,
  saveRequired: PropTypes.bool,
  saving: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  orderNumber: PropTypes.string,
  orderSummaryCollapsed: PropTypes.bool,
  isOrderConfirmation: PropTypes.bool.isRequired,
  onOrderConfirmationMobile: PropTypes.bool.isRequired,
  discountSofteningFreeDeliveryValue: PropTypes.string,
}

const defaultProps = {
  prices: Immutable.Map({}),
  deliverySlot: Immutable.Map(),
  giftItems: Immutable.Map(),
  productItems: Immutable.Map(),
  recipeItems: Immutable.Map(),
  removeProduct: null,
  shippingAddress: Immutable.Map(),
  saveError: false,
  saveRequired: false,
  saving: false,
  orderNumber: '',
  orderSummaryCollapsed: true,
  discountSofteningFreeDeliveryValue: 'c',
}

class OrderSummary extends PureComponent {
  asterisk = String.fromCharCode(42)

  constructor(props) {
    super(props)
    this.state = {
      orderSummaryOpen: false,
    }
  }

  onOrderSave = () => {
    const { isOrderConfirmation, onSave } = this.props
    onSave(isOrderConfirmation)
  }

  toggleDetailView = () => {
    const { orderSummaryOpen } = this.state
    this.setState({
      orderSummaryOpen: !orderSummaryOpen,
    })
  }

  getProducts = () => {
    const { productItems, products, removeProduct } = this.props

    return productItems
      .map((productQty, productId) => {
        const product = products.get(productId, Immutable.Map())

        return {
          orderItemId: productId,
          title: product.get('title'),
          disclaimerKey: product.get('isVatable') ? this.asterisk : '',
          quantity: parseFloat(productQty),
          images: product.get('images'),
          onRemove: removeProduct
            ? () => {
              removeProduct(productId)
            }
            : undefined,
        }
      })
      .toArray()
  }

  getProductGifts = () => {
    const { giftItems, products } = this.props

    return giftItems
      .map((productQty, productId) => {
        const product = products.get(productId, Immutable.Map())

        // Hide gifts
        if (!isNotAGift(product)) {
          return false
        }

        return {
          orderItemId: productId,
          title: product.get('title'),
          quantity: parseFloat(productQty),
          images: product.get('images'),
          gift: true,
        }
      })
      .toArray()
      .filter((item) => item)
  }

  getRecipes = () => {
    const { recipeItems, recipes } = this.props
    const recipesDetails = []

    recipeItems.forEach((r) => {
      const recipeId = r.get('itemableId')
      const recipeQty = r.get('quantity')
      const recipe = recipes.get(recipeId, Immutable.Map())

      if (recipe.has('title') && recipe.has('media')) {
        recipesDetails.push({
          orderItemId: recipeId,
          title: recipe.get('title'),
          numPortions: parseFloat(recipeQty),
          media: recipe.get('media'),
          url: recipe.get('url'),
        })
      }
    })

    return recipesDetails
  }

  renderFooter = () => {
    const { orderSummaryOpen } = this.state

    return (
      <footer className={classnames(css.mobileOnly, css.footer)}>
        {orderSummaryOpen ? (
          <a className={css.toggleLink} onClick={this.toggleDetailView}>
            Hide order details
            <Icon name="fa-angle-up" className={css.footerIcon} />
          </a>
        ) : (
          <a className={css.toggleLink} onClick={this.toggleDetailView}>
            View order details
            <Icon name="fa-angle-down" className={css.footerIcon} />
          </a>
        )}
      </footer>
    )
  }

  render() {
    const {
      prices,
      deliveryDate,
      deliverySlot,
      numPortions,
      numRecipes,
      shippingAddress,
      productItems,
      products,
      orderNumber,
      saving,
      saveRequired,
      saveError,
      orderSummaryCollapsed,
      onOrderConfirmationMobile,
      discountSofteningFreeDeliveryValue,
    } = this.props
    const { orderSummaryOpen } = this.state
    let vatableItemsInOrder = false
    let extrasPrice = 0.0
    let totalToPay = prices.get('total') - prices.get('productTotal')
    const isDiscountSofteningFreeDeliveryEnabled = ['t1', 't2', 't3'].includes(
      discountSofteningFreeDeliveryValue,
    )
    const deliveryPrice = deliveryPriceConfig.deliveryPriceFormatted

    productItems.forEach((productQty, productId) => {
      const product = products.get(productId, Immutable.Map())

      if (product.get('isVatable')) {
        vatableItemsInOrder = true
      }

      extrasPrice += productQty * parseFloat(product.get('listPrice'))
      totalToPay += productQty * parseFloat(product.get('listPrice'))
    })

    return (
      <section className={css.container} data-testid="order-summary-component-section">
        <div
          className={classnames(
            css.details,
            { [css.slideUp]: !orderSummaryOpen && orderSummaryCollapsed },
            { [css.mobileHide]: !orderSummaryOpen && orderSummaryCollapsed },
          )}
        >
          {this.getRecipes().map((recipe) => (
            <Item
              key={recipe.orderItemId}
              type="recipe"
              fromBox={recipe.fromBox}
              media={recipe.media.getIn(['images', 0, 'urls'], Immutable.List([]))}
              onImageClick={null}
              quantity={recipe.numPortions}
              recipeId={recipe.recipeId}
              title={recipe.title}
              url={recipe.url}
              available
            />
          ))}
          {this.getProducts().map((product) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <ProductItem key={product.orderItemId} {...product} available />
          ))}
          {this.getProductGifts().map((product) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <ProductItem key={product.orderItemId} {...product} available />
          ))}

          <div className={css.receipt}>
            <Receipt
              prices={prices}
              deliveryDate={deliveryDate}
              deliverySlot={deliverySlot}
              numPortions={numPortions}
              numRecipes={numRecipes}
              deliveryTotalPrice={prices.get('deliveryTotal')}
              isDeliveryFree={prices.get('isDeliveryFree')}
              shippingAddress={shippingAddress}
              vatableItems={vatableItemsInOrder}
              surchargeCount={prices.get('surchargeCount')}
              surchargeTotal={prices.get('surchargeTotal')}
              recipeTotalPrice={prices.get('recipeTotal')}
              totalToPay={String(totalToPay)}
              recipeDiscountAmount={prices.get('recipeDiscount')}
              recipeDiscountPercent={prices.get('percentageOff')}
              extrasTotalPrice={String(extrasPrice)}
              orderNumber={orderNumber}
              isDiscountSofteningFreeDelivery={isDiscountSofteningFreeDeliveryEnabled}
              flatDeliveryFee={deliveryPrice}
            >
              {vatableItemsInOrder ? (
                <p className={css.disclaimer}>{this.asterisk} These items include VAT at 20%</p>
              ) : null}
            </Receipt>
          </div>
          <UserCreditMessage />
          <div
            className={classnames({ [css.updateOrderButtonSummary]: onOrderConfirmationMobile })}
          >
            <SaveButton
              saving={saving}
              saveRequired={saveRequired}
              onClick={this.onOrderSave}
              error={saveError}
            />
          </div>
        </div>
        {orderSummaryCollapsed ? this.renderFooter() : null}
      </section>
    )
  }
}

OrderSummary.propTypes = propTypes

OrderSummary.defaultProps = defaultProps

export default OrderSummary
