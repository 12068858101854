import React, { useState } from 'react'

import {
  Box,
  Modal,
  useModal,
  ModalClose,
  ModalHeader,
  Body2,
  Space,
  Heading5,
  Visible,
  Display,
  Overflow,
} from '@gousto-internal/citrus-react'
import { useSelector } from 'react-redux'
import { useDebounce } from 'react-use'

import { RecipeTile, RecipeTileDependencies } from '@features/recipe-tile'
import { useLegacyCurrentMenuRecipes } from '@library/menu-service/internal/recipes'
import { queueTrackingEvent } from '@library/tracking-integration'

import { menuSearchModalOpen } from 'actions/trackingKeys'
import { SuggestedRecipe } from 'routes/Menu/components/Search/SuggestedRecipe/SuggestedRecipe'
import { useTag } from 'routes/Menu/context/recipeContext'
import { useBasket } from 'routes/Menu/domains/basket'
import { useCollections } from 'routes/Menu/domains/collections'
import { useMenuPreferences } from 'routes/Menu/domains/menu'
import { useMenuPreferencesWeb } from 'routes/Menu/hooks/useMenuPreferencesWeb'
import { getSignupDietaryPreferences } from 'routes/Signup/signupSelectors'
import { getBasketDate, getBasketMenuUuid } from 'selectors/basket'

import { RelevantRecipesSkeleton } from '../LoadingSkeleton/RelevantRecipesSkeleton'
import { NoResults } from '../NoResults/NoResults'
import { PopularCategories } from '../PopularCategories/PopularCategories'
import { RecipeDetail } from '../RecipeDetail/RecipeDetail'
import { SearchCategoriesGrid } from '../SearchCategoriesGrid/SearchCategoriesGrid'
import { SearchError } from '../SearchError/SearchError'
import { SearchInput } from '../SearchInput/SearchInput'
import { SearchRecipeTile } from '../SearchRecipeTile/SearchRecipeTile'
import { TrackCTAClick } from '../types'
import { getCategoryImageUrl } from '../utils'
import { useMenuSearch } from './useMenuSearch'

import css from './styles.css'

type ViewRecipeDetails = {
  invocationId?: string
  menuId: string
  recipeId: string
  recipeRank?: number
}

export const recipeSearchModalName = 'RecipeSearchModal'

export const RecipeSearchModal = () => {
  const { closeCurrentModal } = useModal()
  const [searchText, setSearchText] = useState('')
  const [debouncedSearchText, setDebouncedSearchText] = useState('')
  const [recipeDetailsId, setRecipeDetailsId] = useState('')
  const basketDate = useSelector(getBasketDate)
  const basketMenuUuid = useSelector(getBasketMenuUuid)
  const { data: menuPreferences } = useMenuPreferences()
  const recipeList = useLegacyCurrentMenuRecipes()
  const isMenuPreferencesWebEnabled = useMenuPreferencesWeb()

  useDebounce(() => setDebouncedSearchText(searchText), 500, [searchText])

  const { data, error, isLoading, retry } = useMenuSearch({
    deliveryDate: basketDate,
    menuId: basketMenuUuid,
    searchText: debouncedSearchText,
  })

  const { addRecipe, isRecipeInBasket, removeRecipe, reachedLimit, numPortions, canAddRecipes } =
    useBasket()
  const useRecipeBrand = () => ({
    useTag,
  })
  const { currentCollectionId } = useCollections()
  const dietaryPreferences = useSelector(getSignupDietaryPreferences)

  const cleanUpState = () => {
    setSearchText('')
    setRecipeDetailsId('')
  }

  const viewRecipeDetails = ({ invocationId, menuId, recipeId, recipeRank }: ViewRecipeDetails) => {
    setRecipeDetailsId(recipeId)
    queueTrackingEvent<{
      action: 'view_recipe_details'
      properties: {
        menu_id: string
        recipe_id: string
        search_invocation_id?: string
        search_rank?: number
      }
    }>({
      action: 'view_recipe_details',
      properties: {
        menu_id: menuId,
        recipe_id: recipeId,
        ...(invocationId && { search_invocation_id: invocationId }),
        ...(recipeRank && { search_rank: recipeRank }),
      },
    })
  }

  const viewSearch = ({ menuId }: { menuId: string }) => {
    setRecipeDetailsId('')
    queueTrackingEvent<{
      action: typeof menuSearchModalOpen
      properties: {
        menu_id: string
      }
    }>({
      action: menuSearchModalOpen,
      properties: {
        menu_id: menuId,
      },
    })
  }

  return (
    <Modal
      className={css.RecipeSearchModal}
      name={recipeSearchModalName}
      onRequestClose={closeCurrentModal}
      onAfterClose={cleanUpState}
      overlayClassName={recipeDetailsId ? css.overlay : ''}
    >
      {recipeDetailsId ? (
        <RecipeDetail
          onClose={() => viewSearch({ menuId: basketMenuUuid })}
          recipeId={recipeDetailsId}
        />
      ) : (
        <ModalHeader>
          <Box paddingRight={[4, 12]}>
            <SearchInput onChange={setSearchText} value={searchText} />
          </Box>
          <ModalClose onClose={closeCurrentModal} />
        </ModalHeader>
      )}
      <Box
        display={recipeDetailsId ? Display.None : Display.Initial}
        overflowY={Overflow.Scroll}
        paddingV={[4, 6]}
        paddingH={[4, 6]}
      >
        <Box paddingH={2} paddingBottom={4}>
          {(() => {
            if (error) {
              return <SearchError onRetry={retry} />
            } else if (data) {
              const { categories, recipes, invocation_id: invocationId } = data

              return (
                <Box>
                  <Box paddingBottom={6}>
                    {recipes.length > 0 ? (
                      <>
                        <Heading5>Relevant Recipes</Heading5>
                        <Body2>On this week&apos;s menu</Body2>
                        <Space size={4} />
                        {recipes.map((recipe, index) => {
                          const trackingData = {
                            invocationId,
                            menuId: basketMenuUuid,
                            index,
                          }

                          const menuServiceRecipe = recipeList.find(
                            (serviceRecipe) => serviceRecipe.get('uuid') === recipe.id,
                          )

                          const searchTrackingData = {
                            search_invocation_id: invocationId,
                            search_rank: index,
                            menu_id: basketMenuUuid,
                            add_type: 'search',
                            recipe_id: recipe.id,
                          }

                          return (
                            <React.Fragment key={recipe.id}>
                              <Visible display={[Display.None, Display.Block]}>
                                <Box paddingBottom={2}>
                                  <SearchRecipeTile
                                    addRecipe={addRecipe}
                                    isMenuPreferencesWebEnabled={isMenuPreferencesWebEnabled}
                                    isRecipeInBasket={isRecipeInBasket}
                                    menuPreferences={menuPreferences}
                                    onClick={() =>
                                      viewRecipeDetails({
                                        invocationId,
                                        menuId: basketMenuUuid,
                                        recipeId: recipe.id,
                                        recipeRank: index,
                                      })
                                    }
                                    reachedLimit={reachedLimit}
                                    recipe={recipe}
                                    removeRecipe={removeRecipe}
                                    trackingData={trackingData}
                                  />
                                </Box>
                              </Visible>
                              <Visible display={[Display.Block, Display.None]}>
                                {menuServiceRecipe && (
                                  <Box paddingBottom={4}>
                                    <RecipeTileDependencies useRecipeBrand={useRecipeBrand}>
                                      <RecipeTile
                                        recipe={menuServiceRecipe.toJS()}
                                        numPortions={numPortions}
                                        addRecipe={(recipeId) => {
                                          queueTrackingEvent<TrackCTAClick>({
                                            action: 'add_recipe',
                                            properties: searchTrackingData,
                                          })
                                          addRecipe(recipeId)
                                        }}
                                        alternativeOptions={[]}
                                        canAddRecipes={canAddRecipes}
                                        currentCollectionId={currentCollectionId as string}
                                        dietaryPreferences={dietaryPreferences}
                                        isMenuPersonalisationEnabled={false}
                                        isMenuPreferencesWebEnabled={isMenuPreferencesWebEnabled}
                                        isRecipeInBasket={isRecipeInBasket}
                                        isRecipeOutOfStock={() => false}
                                        onClick={() =>
                                          viewRecipeDetails({
                                            invocationId,
                                            menuId: basketMenuUuid,
                                            recipeId: recipe.id,
                                            recipeRank: index,
                                          })
                                        }
                                        originalId={recipe.id}
                                        reachedLimit={reachedLimit}
                                        recipeReference=""
                                        removeRecipe={(recipeId) => {
                                          queueTrackingEvent<TrackCTAClick>({
                                            action: 'remove_recipe',
                                            properties: searchTrackingData,
                                          })
                                          removeRecipe(recipeId)
                                        }}
                                        selectVariant={() => {}}
                                        setBrowserCTAVisible={() => {}}
                                        menuPreferences={menuPreferences}
                                        insideOverflow
                                      />
                                    </RecipeTileDependencies>
                                  </Box>
                                )}
                              </Visible>
                            </React.Fragment>
                          )
                        })}
                      </>
                    ) : (
                      <NoResults />
                    )}
                  </Box>
                  {categories.length > 0 && (
                    <Box paddingBottom={4}>
                      <SearchCategoriesGrid
                        categories={categories.map((category) => ({
                          id: category.id,
                          imageUrl: getCategoryImageUrl(category.id),
                          name: category.short_title,
                          recipeCount: category.recipe_count,
                        }))}
                        title="Related Categories"
                      />
                    </Box>
                  )}
                </Box>
              )
            } else if (isLoading) {
              return <RelevantRecipesSkeleton />
            } else {
              return (
                <>
                  <PopularCategories />
                  <Space size={4} />
                  <SuggestedRecipe
                    addRecipe={addRecipe}
                    canAddRecipes={canAddRecipes}
                    isRecipeInBasket={isRecipeInBasket}
                    menuPreferences={menuPreferences}
                    numPortions={numPortions}
                    onClick={(recommendedRecipeId) =>
                      viewRecipeDetails({
                        menuId: basketMenuUuid,
                        recipeId: recommendedRecipeId,
                      })
                    }
                    reachedLimit={reachedLimit}
                    removeRecipe={removeRecipe}
                  />
                </>
              )
            }
          })()}
        </Box>
      </Box>
    </Modal>
  )
}
