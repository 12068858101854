import React from 'react'

import { AlignItems, Box, Color, Display, Icon, Text } from '@gousto-internal/citrus-react'

type InvalidPreferenceTagProps = {
  text: string
}

export const InvalidPreferenceTag = ({ text }: InvalidPreferenceTagProps) => (
  <Box data-testid="invalid-preference-tag">
    <Box
      borderRadius={1.5}
      bg={Color.ColdGrey_900}
      height="24px"
      display={Display.Flex}
      alignItems={AlignItems.Center}
      gap={1}
      width="fit-content"
    >
      <Box
        data-testid="info-icon"
        style={{ borderTopLeftRadius: 1.5, borderBottomLeftRadius: 1.5 }}
        bg={Color.Warning_700}
        width="22px"
        paddingH={0.75}
        paddingV={1}
        display={Display.Flex}
      >
        <Icon name="info" style={{ color: Color.White }} size={4} />
      </Box>
      <Box data-testid="invalid-preference-text" bg={Color.ColdGrey_900} paddingRight={2}>
        <Text size={2} color={Color.White}>
          {text}
        </Text>
      </Box>
    </Box>
  </Box>
)
