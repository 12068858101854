import React from 'react'

import { MyGoustoPage } from '@features/my-gousto-page'

import { ReferAFriendContainer } from './ReferAFriendContainer'

const MyGousto = () => (
  <div data-testid="my-gousto">
    <MyGoustoPage />
    <ReferAFriendContainer />
  </div>
)

export { MyGousto }
