import React from 'react'

import {
  Button as CitrusButton,
  AlignItems,
  Box,
  ButtonColorVariant,
  Display,
  FontFamily,
  Icon,
  IconVariant,
  JustifyContent,
  Link,
  Text,
} from '@gousto-internal/citrus-react'
import { Button, Segment } from 'goustouicomponents'
import Immutable from 'immutable'
import moment from 'moment'

import { client as clientRoutes } from 'config/routes'
import { getSlotTimes } from 'utils/deliveries'

import css from '../Details.css'

type DateHeaderProps = {
  orderId: string
  date: string
  deliveryDays: Immutable.Map<string, any>
  slotId: string
  clearSlot: () => void
  useAlignedVersion?: boolean
}

export const DateHeader: React.FC<DateHeaderProps> = (props) => {
  const { orderId, date, deliveryDays, slotId, clearSlot, useAlignedVersion } = props

  if (orderId) {
    return (
      <div className={css.row}>
        <p className={css.deliverySlotText}>
          Edit recipes for your upcoming order. To change date or cancel order, visit{' '}
          <Link href={clientRoutes.myDeliveries} size={1} style={{ boxShadow: 'none' }}>
            &apos;Upcoming deliveries&apos;
          </Link>
        </p>
        <p className={css.dateText}>{`${moment(date).format('ddd Do MMM')}, ${getSlotTimes({
          date,
          deliveryDays,
          slotId,
        })}`}</p>
      </div>
    )
  }
  const text = `${moment(date).format('ddd Do MMM')}, ${getSlotTimes({
    date,
    deliveryDays,
    slotId,
  })}`

  if (useAlignedVersion) {
    return (
      <CitrusButton
        colorVariant={ButtonColorVariant.Secondary}
        width="100%"
        data-testid="edit-delivery-slot-citrus-version-spaceBetween"
        onClick={() => {
          clearSlot()
        }}
      >
        <Box
          display={Display.Flex}
          width="100%"
          justifyContent={JustifyContent.SpaceBetween}
          alignItems={AlignItems.Center}
        >
          <Text size={4} fontFamily={FontFamily.Bold} ellipsis>
            {text}
          </Text>
          <Box display={Display.Flex}>
            <Icon size={4} name="edit" variant={IconVariant.Actionable} />
            <Text size={1} fontFamily={FontFamily.Bold}>
              edit
            </Text>
          </Box>
        </Box>
      </CitrusButton>
    )
  }

  // This version is out of alignment with the portions version in production
  // ToDo - remove this version of the component if citrus version can be used everywhere

  return (
    <div data-testid="edit-delivery-slot">
      <Button fill={false} width="full">
        <Segment onClick={clearSlot} fill={false}>
          <span className={text.length > 21 ? css.limitedLengthPadding : css.limitedLength}>
            {text}
          </span>
          <span className={css.clear}>
            <span className={css.clearIcon} />
            edit
          </span>
        </Segment>
      </Button>
    </div>
  )
}
