import React from 'react'

import { Body3, Color, Display, Strong } from '@gousto-internal/citrus-react'
import PropTypes from 'prop-types'

import { useNumPortions } from 'routes/Menu/domains/basket/internal/useNumPortions'
import { useSupportedBoxTypes } from 'routes/Menu/domains/basket/internal/useSupportedBoxTypes'

const BoxProgressMessage = ({ numRecipes, className, showPricePerServingMessaging }) => {
  const { maxRecipesForPortion, minRecipesForPortion } = useSupportedBoxTypes()
  const { numPortions } = useNumPortions()
  const maxRecipesNum = maxRecipesForPortion(numPortions)
  const minRecipesNum = minRecipesForPortion(numPortions)

  if (showPricePerServingMessaging) {
    if (numRecipes <= 0) {
      return (
        <p className={className}>
          Add <strong>{maxRecipesNum}</strong> recipes for the best price per serving
        </p>
      )
    } else if (numRecipes < minRecipesNum) {
      return <p className={className}>Add more recipes to complete your box</p>
    } else if (numRecipes >= maxRecipesNum) {
      return <p className={className}>Nice! You&apos;ve got the best price per serving</p>
    } else {
      const remainingRecipes = maxRecipesNum - numRecipes

      return (
        <p className={className}>
          Add <strong>{remainingRecipes}</strong> more recipe
          {remainingRecipes > 1 ? 's' : ''} for the best price per serving
        </p>
      )
    }
  }

  if (numRecipes < minRecipesNum) {
    return (
      <Body3 display={Display.Inline} textAlign="left" size={[1, 2]}>
        <Strong color={Color.Informative_900}>Add at least {minRecipesNum} recipes</Strong> to check
        out
      </Body3>
    )
  }

  return null
}

BoxProgressMessage.propTypes = {
  numRecipes: PropTypes.number.isRequired,
  className: PropTypes.string,
  showPricePerServingMessaging: PropTypes.bool,
}

BoxProgressMessage.defaultProps = {
  className: '',
  showPricePerServingMessaging: true,
}

export { BoxProgressMessage }
