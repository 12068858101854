import { composeFetch } from '@library/http'
import {
  composeRequest,
  addPath,
  setServiceUrl,
  setMethod,
  setQueryParams,
  setHeaders,
} from '@library/http/requests'
import { MenuSearch } from './types'
import { getAuthUserId } from '@library/auth'

type MenuSearchRequestParams = {
  accessToken?: string
  deliveryDate: string
  menuId: string
  numPortions: number
  search: string
}

const menuApiMenuSearchParse = async (response: Response): Promise<MenuSearch> => {
  if (!response.ok) {
    throw new Error(`menu search failed with status ${response.status}`)
  }

  return response.json()
}

const menuApiMenuSearchRequest = composeRequest(
  setServiceUrl('menu', 3),
  addPath(({ menuId }: MenuSearchRequestParams) => `menus/${menuId}/search`),
  setQueryParams(({ deliveryDate, numPortions, search }: MenuSearchRequestParams) => {
    const userId = getAuthUserId()

    return {
      delivery_date: deliveryDate,
      num_portions: numPortions.toString(),
      search: search,
      ...(userId && { user_id: userId }),
    }
  }),
  setHeaders(({ accessToken }) => {
    return {
      ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
    }
  }),
  setMethod('GET'),
)

export const menuSearchFetcher = composeFetch(menuApiMenuSearchRequest, menuApiMenuSearchParse)
