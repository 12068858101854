import React from 'react'
import { CSSObject } from '@emotion/react'
import {
  AlignItems,
  Box,
  Color,
  Display,
  Display1,
  DisplayPosition,
  FlexDirection,
  FontWeight,
  Heading5,
  Overflow,
  Space,
  Text,
  TextAlign,
  withCitrusTheme,
  ZIndex,
} from '@gousto-internal/citrus-react'

import { HeroSlides } from '../../../../config'
import ResponsiveImage from '../ResponsiveImage/ResponsiveImage'

import topLeftMobileWebp from '../../../../assets/carousel/hero/mobile/prawn-noodles.webp'
import topLeftMobile1xPng from '../../../../assets/carousel/hero/mobile/prawn-noodles.png'
import topLeftMobile2xPng from '../../../../assets/carousel/hero/mobile/prawn-noodles@2x.png'
import topLeftDesktopWebp from '../../../../assets/carousel/hero/desktop/prawn-noodles.webp'
import topLeftDesktop1xPng from '../../../../assets/carousel/hero/desktop/prawn-noodles.png'
import topLeftDesktop2xPng from '../../../../assets/carousel/hero/desktop/prawn-noodles@2x.png'

import topRightMobileWebp from '../../../../assets/carousel/hero/mobile/katsu.webp'
import topRightMobile1xPng from '../../../../assets/carousel/hero/mobile/katsu.png'
import topRightMobile2xPng from '../../../../assets/carousel/hero/mobile/katsu@2x.png'
import topRightDesktopWebp from '../../../../assets/carousel/hero/desktop/katsu.webp'
import topRightDesktop1xPng from '../../../../assets/carousel/hero/desktop/katsu.png'
import topRightDesktop2xPng from '../../../../assets/carousel/hero/desktop/katsu@2x.png'

import bottomRightMobileWebp from '../../../../assets/carousel/hero/mobile/salmon-tacos.webp'
import bottomRightMobile1xPng from '../../../../assets/carousel/hero/mobile/salmon-tacos.png'
import bottomRightMobile2xPng from '../../../../assets/carousel/hero/mobile/salmon-tacos@2x.png'
import bottomRightDesktopWebp from '../../../../assets/carousel/hero/desktop/salmon-tacos.webp'
import bottomRightDesktop1xPng from '../../../../assets/carousel/hero/desktop/salmon-tacos.png'
import bottomRightDesktop2xPng from '../../../../assets/carousel/hero/desktop/salmon-tacos@2x.png'

import bottomLeftMobileWebp from '../../../../assets/carousel/hero/mobile/salad.webp'
import bottomLeftMobile1xPng from '../../../../assets/carousel/hero/mobile/salad.png'
import bottomLeftMobile2xPng from '../../../../assets/carousel/hero/mobile/salad@2x.png'
import bottomLeftDesktopWebp from '../../../../assets/carousel/hero/desktop/salad.webp'
import bottomLeftDesktop1xPng from '../../../../assets/carousel/hero/desktop/salad.png'
import bottomLeftDesktop2xPng from '../../../../assets/carousel/hero/desktop/salad@2x.png'

export const HeroSlide = () => {
  const {
    titlePart1,
    titlePart2,
    titlePart3,
    titlePart4,
    titlePart5,
    subTitlePart1,
    subTitlePart2,
  } = HeroSlides
  return (
    <Box
      data-testid="hero-slide"
      display={Display.Flex}
      flexDirection={FlexDirection.Column}
      alignItems={AlignItems.Center}
      minHeight={'610px'}
      position={DisplayPosition.Relative}
      overflowX={Overflow.Hidden}
      overflowY={Overflow.Hidden}
    >
      <Box width={['307px', null, null, '460px']} zIndex={ZIndex.Mask}>
        <Space size={16} />
        <Space size={[6, null, 18]} />
        <StyledDisplay1
          data-testid="hero-slide-title"
          color={Color.White}
          textAlign={TextAlign.Center}
          display={Display.Flex}
        >
          <StyledText>{titlePart1}</StyledText>
          <StyledText>{titlePart2}</StyledText>
          <StyledText>{titlePart3}</StyledText>
          <StyledText>{titlePart4}</StyledText>
          <StyledText>
            {titlePart5}
            <sup>*</sup>
          </StyledText>
        </StyledDisplay1>
      </Box>
      <Space size={1} />
      <Box width={['307px', null, null, '600px']} zIndex={ZIndex.Mask}>
        <StyledHeading5
          data-testid="hero-slide-subtitle"
          color={Color.White}
          fontWeight={FontWeight.Bold}
          textAlign={TextAlign.Center}
          display={Display.Flex}
        >
          <StyledText>{subTitlePart1}</StyledText>
          <StyledText>{subTitlePart2}</StyledText>
        </StyledHeading5>
      </Box>
      <Box
        position={DisplayPosition.Absolute}
        width={['252px', '272px', '272px', '472px', '532px']}
        height={['252px', '272px', '272px', '472px', '532px']}
        top={['-40px', '-56px', '-56px', '-94px', '-126px']}
        left={[
          'calc(50% - 148px)',
          'calc(50% - 148px)',
          'calc(50% - 132px)',
          'calc(50% - 354px)',
          'calc(50% - 398px)',
        ]}
        style={{
          transform: 'translateX(-50%)',
        }}
      >
        <ResponsiveImage
          mobileWebp={topLeftMobileWebp}
          mobile1xPng={topLeftMobile1xPng}
          mobile2xPng={topLeftMobile2xPng}
          desktopWebp={topLeftDesktopWebp}
          desktop1xPng={topLeftDesktop1xPng}
          desktop2xPng={topLeftDesktop2xPng}
          alt="A dish of prawn noodles with fresh broccoli, red chili slices, and spring onions, served in a patterned bowl"
        />
      </Box>
      <Box
        position={DisplayPosition.Absolute}
        width={['348px', '400px', '400px', '420px', '430px']}
        height={['348px', '400px', '400px', '420px', '430px']}
        top={['-90px', '-122px', '-114px', '-148px', '-156px']}
        right={[
          'calc(50% - 210px)',
          'calc(50% - 200px)',
          'calc(50% - 214px)',
          'calc(50% - 356px)',
          'calc(50% - 390px)',
        ]}
        style={{
          transform: 'translateX(50%)',
        }}
      >
        <ResponsiveImage
          mobileWebp={topRightMobileWebp}
          mobile1xPng={topRightMobile1xPng}
          mobile2xPng={topRightMobile2xPng}
          desktopWebp={topRightDesktopWebp}
          desktop1xPng={topRightDesktop1xPng}
          desktop2xPng={topRightDesktop2xPng}
          alt="A crispy breaded katsu served on a bed of white rice and drizzled with curry sauce"
        />
      </Box>
      <Box
        position={DisplayPosition.Absolute}
        width={['340px', '354px', '354px', '680px', '720px']}
        height={['340px', '354px', '354px', '680px', '720px']}
        bottom={['52px', '48px', '60px', '-268px', '-302px']}
        right={[
          'calc(50% - 204px)',
          'calc(50% - 210px)',
          'calc(50% - 282px)',
          'calc(50% - 300px)',
          'calc(50% - 348px)',
        ]}
        style={{
          transform: 'translateX(50%)',
        }}
      >
        <ResponsiveImage
          mobileWebp={bottomRightMobileWebp}
          mobile1xPng={bottomRightMobile1xPng}
          mobile2xPng={bottomRightMobile2xPng}
          desktopWebp={bottomRightDesktopWebp}
          desktop1xPng={bottomRightDesktop1xPng}
          desktop2xPng={bottomRightDesktop2xPng}
          alt="Three salmon tacos filled with shredded vegetables served on a wooden board with a lime wedge"
        />
      </Box>
      <Box
        position={DisplayPosition.Absolute}
        width={['286px', '308px', '308px', '452px', '526px']}
        height={['286px', '308px', '308px', '452px', '526px']}
        bottom={['54px', '70px', '70px', '-100px', '-154px']}
        left={[
          'calc(50% - 190px)',
          'calc(50% - 220px)',
          'calc(50% - 242px)',
          'calc(50% - 452px)',
          'calc(50% - 490px)',
        ]}
        style={{
          transform: 'translateX(-50%)',
        }}
      >
        <ResponsiveImage
          mobileWebp={bottomLeftMobileWebp}
          mobile1xPng={bottomLeftMobile1xPng}
          mobile2xPng={bottomLeftMobile2xPng}
          desktopWebp={bottomLeftDesktopWebp}
          desktop1xPng={bottomLeftDesktop1xPng}
          desktop2xPng={bottomLeftDesktop2xPng}
          alt="A colorful salad featuring prosciutto on a bed of mixed greens"
        />
      </Box>
    </Box>
  )
}

const StyledDisplay1 = withCitrusTheme(Display1, (theme) => ({
  ...(theme.responsiveStyle(
    [FlexDirection.Column, null, FlexDirection.Row],
    ['flexDirection'],
  ) as CSSObject),
  flexWrap: 'wrap',
  justifyContent: 'center',
  gap: '0 8px',
}))

const StyledHeading5 = withCitrusTheme(Heading5, (theme) => ({
  ...(theme.responsiveStyle(
    [FlexDirection.Column, null, FlexDirection.Row],
    ['flexDirection'],
  ) as CSSObject),
  flexWrap: 'wrap',
  justifyContent: 'center',
  gap: '0 5px',
}))

const StyledText = ({ children }: { children: React.ReactNode }) => {
  return (
    <Text
      style={{
        lineHeight: 'inherit',
        fontSize: 'inherit',
        fontFamily: 'inherit',
        textAlign: 'inherit',
      }}
    >
      {children}
    </Text>
  )
}
