import React from 'react'

import {
  AlignItems,
  BorderStyle,
  Box,
  Color,
  Display,
  JustifyContent,
  Strong,
  Text,
  Visible,
} from '@gousto-internal/citrus-react'

export const InformationBanner = () => (
  <Visible display={[Display.None, Display.Block]}>
    <Box
      data-testid="menu-information-banner"
      display={Display.Flex}
      bg={Color.Informative_50}
      borderStyle={BorderStyle.Solid}
      borderColor={Color.Informative_200}
      borderRadius={1.5}
      borderWidth={0.5}
      width={['0px', '306px']}
      height="48px"
      paddingV={3}
      justifyContent={JustifyContent.Center}
      alignItems={AlignItems.Center}
    >
      <Text size={4} color={Color.ColdGrey_800}>
        <Strong color={Color.Informative_900}>{`Add at least 2 recipes `}</Strong>
        to check out
      </Text>
    </Box>
  </Visible>
)
