import React from 'react'

import {
  Body2,
  Box,
  Checkbox,
  Color,
  FontWeight,
  Heading5,
  Space,
  Text,
} from '@gousto-internal/citrus-react'

import { DietaryPreference } from '@library/menu-service/queries/menuApiPreferences/types'

type DietaryPreferencesProps = {
  dietaryPreferences: DietaryPreference[]
  onChange: (selectedDietaryPreferences: DietaryPreference[]) => void
}

export const DietaryPreferences = ({ dietaryPreferences, onChange }: DietaryPreferencesProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target
    const updatedPreferences = dietaryPreferences.map((preference) =>
      preference.slug === name ? { ...preference, selected: checked } : preference,
    )
    onChange(updatedPreferences)
  }

  return (
    <Box paddingV={2}>
      <Heading5>Dietary preferences</Heading5>
      <Body2>What are your household&apos;s eating habits?</Body2>
      <Space size={3} direction="vertical" />
      {dietaryPreferences.map((preference) => (
        <Checkbox
          key={preference.slug}
          id={preference.slug}
          checked={preference.selected}
          name={preference.slug}
          onChange={handleChange}
          outline
          paddingH={3}
          paddingV={3}
        >
          <Text
            color={preference.selected ? Color.Secondary_400 : Color.ColdGrey_800}
            fontWeight={preference.selected ? FontWeight.Bold : FontWeight.Normal}
            size={3}
          >
            {preference.name}
          </Text>
        </Checkbox>
      ))}
    </Box>
  )
}
