import React from 'react'

import classnames from 'classnames'
import PropTypes from 'prop-types'

import OrderSummary from 'containers/welcome/OrderSummary'

import { ReferAFriend } from '../OrderConfirmation/components/ReferAFriend'
import { AppPromo } from './AppPromo'
import { AwinPixelContainer } from './AwinPixel'
import { OrderSchedule } from './OrderSchedule'
import { WelcomeFirstBoxOrderWrapper } from './WelcomeFirstBoxOrderWrapper'

import css from './Welcome.css'

const propTypes = {
  device: PropTypes.string.isRequired,
  fetchData: PropTypes.func.isRequired,
  orderId: PropTypes.string.isRequired,
  params: PropTypes.shape({
    orderId: PropTypes.string,
  }).isRequired,
  query: PropTypes.shape({
    var: PropTypes.string,
  }).isRequired,
  trackWelcomeAppPromoClick: PropTypes.func.isRequired,
  userFetchReferralOffer: PropTypes.func.isRequired,
  addRecipeToBasket: PropTypes.func.isRequired,
  userLoadData: PropTypes.func.isRequired,
  discountSofteningFreeDeliveryValue: PropTypes.string.isRequired,
  isSignUpFirstTwoBoxesExperimentEnabled: PropTypes.bool.isRequired,
}

class Welcome extends React.PureComponent {
  componentDidMount() {
    const {
      query = {},
      params = {},
      fetchData,
      addRecipeToBasket,
      userFetchReferralOffer,
      userLoadData,
    } = this.props

    userLoadData()
    userFetchReferralOffer()
    fetchData({ query, params }, addRecipeToBasket)
  }

  render() {
    const {
      device,
      trackWelcomeAppPromoClick,
      orderId,
      discountSofteningFreeDeliveryValue,
      isSignUpFirstTwoBoxesExperimentEnabled,
    } = this.props

    if (isSignUpFirstTwoBoxesExperimentEnabled) {
      return (
        <div data-testid="beetroots_1_2_orders">
          <WelcomeFirstBoxOrderWrapper
            discountSofteningFreeDeliveryValue={discountSofteningFreeDeliveryValue}
            isSignUpFirstTwoBoxesExperimentEnabled={isSignUpFirstTwoBoxesExperimentEnabled}
          />
        </div>
      )
    } else {
      return (
        <section className={css.container} data-testid="welcomeContainer">
          <div className={classnames(css.contentContainer)}>
            <div className={css.row}>
              <div className={css.colMedium}>
                <div className={css.welcomeColInnerContainer}>
                  <OrderSchedule
                    discountSofteningFreeDeliveryValue={discountSofteningFreeDeliveryValue}
                  />
                  <div className={classnames(css.welcomeColInner, css.appPromo)}>
                    <AppPromo
                      device={device}
                      trackWelcomeAppPromoClick={trackWelcomeAppPromoClick}
                    />
                  </div>
                </div>
                <div className={classnames(css.welcomeColInner, css.mobileShow, css.rafMobile)}>
                  <ReferAFriend />
                </div>
              </div>
              <div className={classnames(css.colSmall, css.mobileHide)}>
                <div className={classnames(css.welcomeColInner, css.orderSummaryWrapper)}>
                  <OrderSummary
                    discountSofteningFreeDeliveryValue={discountSofteningFreeDeliveryValue}
                  />
                </div>
                <div className={classnames(css.welcomeColInner)}>
                  <ReferAFriend />
                </div>
              </div>
            </div>
          </div>
          <AwinPixelContainer orderId={orderId} />
        </section>
      )
    }
  }
}

Welcome.propTypes = propTypes

export { Welcome }
