import { connect } from 'react-redux'

import { getBasketOrderId } from 'selectors/basket'
import { getUserOrders } from 'selectors/user'
import { getUserOrderById } from 'utils/user'

import { YourOrderSummary } from './YourOrderSummary'

const mapSateToProps = (state: any) => {
  const { user } = state
  const orderId = getBasketOrderId(state)
  const userOrders = getUserOrders({ user })
  const order = getUserOrderById(orderId, userOrders)

  const deliveryDate = order.get('deliveryDate')
  const cutOffDate = order.get('shouldCutoffAt')

  return {
    deliveryDate,
    cutOffDate,
  }
}

const YourOrderSummaryContainer = connect(mapSateToProps)(YourOrderSummary)
export { YourOrderSummaryContainer }
