import React from 'react'

import {
  AlignItems,
  BorderStyle,
  Box,
  Color,
  Display,
  Icon,
  IconVariant,
} from '@gousto-internal/citrus-react'
import { Alert } from 'goustouicomponents'
import PropTypes from 'prop-types'

import { PricePerServingMessage } from 'components/PricePerServingMessage'
import { TooManyRecipesWarning } from 'routes/Menu/components/TooManyRecipesWarning/TooManyRecipesWarning'
import { useNumRecipesOverBasketLimit } from 'routes/Menu/domains/basket'
import { useMenuBox } from 'routes/Menu/domains/basket/internal/useMenuBox'
import { useNumPortions } from 'routes/Menu/domains/basket/internal/useNumPortions'
import { useSupportedBoxTypes } from 'routes/Menu/domains/basket/internal/useSupportedBoxTypes'

import { BoxProgressMessage } from '../../../BoxProgressMessage'

const BoxProgressAlert = ({ numRecipes, showPricePerServingMessage = true }) => {
  const { maxRecipesForPortion, minRecipesForPortion } = useSupportedBoxTypes()
  const { numPortions } = useNumPortions()
  const maxRecipesNum = maxRecipesForPortion(numPortions)
  const minRecipesNum = minRecipesForPortion(numPortions)
  const hasMaxRecipes = numRecipes >= maxRecipesNum
  const hasMinRecipes = numRecipes >= minRecipesNum
  const numRecipesOverLimit = useNumRecipesOverBasketLimit()
  const menuBox = useMenuBox()

  /* It doesn't make sense to show basket progress if we don't know what the maximum allowed number of recipe
  for the chosen portion size is, so we return null until we get that information from the menu-service. */
  if (!menuBox) {
    return null
  }

  if (numRecipesOverLimit) {
    return (
      <Box paddingBottom={4}>
        <TooManyRecipesWarning numRecipesOverLimit={numRecipesOverLimit} />
      </Box>
    )
  }

  if (!showPricePerServingMessage) {
    if (hasMinRecipes) {
      return null
    } else {
      return (
        <Box paddingBottom={10}>
          <Box
            data-testing="minimumRecipesBoxProgressAlert"
            display={Display.Flex}
            alignItems={AlignItems.Center}
            bg={Color.Informative_50}
            borderColor={Color.Informative_200}
            width="75"
            borderRadius={3}
            borderStyle={BorderStyle.Solid}
            borderWidth={1}
            paddingH={4}
            paddingV={4}
          >
            <Box display={Display.Flex} paddingRight={2}>
              <Icon name="info" size={5} variant={IconVariant.Informative} />
            </Box>
            <BoxProgressMessage
              numRecipes={numRecipes}
              showPricePerServingMessaging={showPricePerServingMessage}
            />
          </Box>
        </Box>
      )
    }
  }

  return (
    <div data-testing="boxProgressAlert">
      <Alert type={hasMaxRecipes ? 'success' : 'info'}>
        <PricePerServingMessage />
        <BoxProgressMessage numRecipes={numRecipes} />
      </Alert>
    </div>
  )
}

BoxProgressAlert.propTypes = {
  numRecipes: PropTypes.number.isRequired,
  showPricePerServingMessage: PropTypes.bool.isRequired,
}

export { BoxProgressAlert }
