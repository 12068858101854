import React from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { menuPreferencesVisibilityChange } from 'actions/menu'
import { getMenuPreferencesShow } from 'selectors/root'

import { Sidebar } from '../Sidebar/Sidebar'
import { MenuPreferencesForm } from './MenuPreferencesForm'

export const MenuPreferencesSidebar = () => {
  const dispatch = useDispatch()
  const showMenuPreferences = useSelector(getMenuPreferencesShow)
  const toggleSidebar = () => dispatch(menuPreferencesVisibilityChange(!showMenuPreferences))

  return (
    <Sidebar onClose={toggleSidebar} isOpen={showMenuPreferences}>
      <MenuPreferencesForm onClose={toggleSidebar} />
    </Sidebar>
  )
}
