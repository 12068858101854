import React from 'react'

import classNames from 'classnames'
import Immutable from 'immutable'
import PropTypes from 'prop-types'

import {
  formatLabelPlural,
  formatPrice,
  formatRecipeDiscount,
  formatSurcharge,
} from 'utils/format'

import { DeliveryDetails } from './DeliveryDetails'
import { ReceiptLine } from './ReceiptLine'

import css from './Receipt.css'

const pound = String.fromCharCode(163)
export const formatDashOrPrice = (price, numRecipes, prices, dash = null, isNumRecipesOverBasketLimit) => {
  if (numRecipes < 2 || !prices || isNumRecipesOverBasketLimit) {
    return (
      <span>
        {pound}
        {dash}
      </span>
    )
  }

  return formatPrice(price)
}

const freeFirstDelivery = (flatDeliveryFee, showPoundSign) => (
  <div dataTesting="emphasiseFreeFirstDelivery">
    <span className={css.undiscountedBoxPrice}>{showPoundSign ? `£${flatDeliveryFee}` : flatDeliveryFee}</span>
    <span className={css.discountEmphasis}>FREE</span>
  </div>
)

export const formatDeliveryTotal = (isDeliveryFree, deliveryTotalPrice, dash = null, isNumRecipesOverBasketLimit, isDiscountSofteningFreeDelivery, flatDeliveryFee, emphasiseFreeDelivery) => {
  if ((deliveryTotalPrice && !isNumRecipesOverBasketLimit) || emphasiseFreeDelivery) {
    if (isDiscountSofteningFreeDelivery || emphasiseFreeDelivery) {
      const showPoundSign = emphasiseFreeDelivery

      return freeFirstDelivery(flatDeliveryFee, showPoundSign)
    } else {
      return !isDeliveryFree ? formatPrice(deliveryTotalPrice) : 'FREE'
    }
  }

  return (
    <span>
      {pound}
      {dash}
    </span>
  )
}

const propTypes = {
  prices: PropTypes.instanceOf(Object),
  children: PropTypes.node,
  numRecipes: PropTypes.number,
  deliveryTotalPrice: PropTypes.string,
  shippingAddress: PropTypes.instanceOf(Immutable.Map),
  deliveryDate: PropTypes.string,
  deliverySlot: PropTypes.instanceOf(Immutable.Map),
  surchargeCount: PropTypes.number,
  surchargeTotal: PropTypes.string,
  totalToPay: PropTypes.string,
  recipeTotalPrice: PropTypes.string,
  recipeDiscountAmount: PropTypes.string,
  recipeDiscountPercent: PropTypes.string,
  extrasTotalPrice: PropTypes.string,
  showTitleSection: PropTypes.bool,
  orderNumber: PropTypes.string,
  isReceiptInCheckout: PropTypes.bool,
  isDeliveryFree: PropTypes.bool,
  numRecipesOverBasketLimit: PropTypes.bool,
  isDiscountSofteningFreeDelivery: PropTypes.bool,
  flatDeliveryFee: PropTypes.string,
  emphasiseFreeDelivery: PropTypes.bool,
  alwaysShowRecipeDiscount: PropTypes.bool,
  showDoubleCurrencyDash: PropTypes.bool,
}

const defaultProps = {
  prices: {},
  children: null,
  numRecipes: 0,
  deliveryTotalPrice: '',
  shippingAddress: null,
  deliveryDate: null,
  deliverySlot: Immutable.Map(),
  surchargeCount: 0,
  surchargeTotal: '',
  totalToPay: '',
  recipeTotalPrice: '',
  recipeDiscountAmount: '',
  recipeDiscountPercent: '',
  extrasTotalPrice: '',
  showTitleSection: false,
  orderNumber: '',
  isReceiptInCheckout: false,
  isDeliveryFree: null,
  numRecipesOverBasketLimit: false,
  isDiscountSofteningFreeDelivery: false,
  flatDeliveryFee: '',
  emphasiseFreeDelivery: false,
  alwaysShowRecipeDiscount: false,
  showDoubleCurrencyDash: false,
}

const getFormattedCurrencyPlaceholder = (showDoubleDash) => {
  const dash = showDoubleDash ? '--' : '—'

  return <span className={css.dash}>{dash}</span>
}

export class Receipt extends React.Component {
  render() {
    const {
      prices,
      recipeTotalPrice,
      totalToPay,
      extrasTotalPrice,
      numRecipes,
      shippingAddress,
      surchargeCount,
      surchargeTotal,
      recipeDiscountAmount,
      recipeDiscountPercent,
      deliveryTotalPrice,
      showTitleSection,
      orderNumber,
      isReceiptInCheckout,
      deliveryDate,
      deliverySlot,
      children,
      isDeliveryFree,
      numRecipesOverBasketLimit,
      isDiscountSofteningFreeDelivery,
      flatDeliveryFee,
      emphasiseFreeDelivery,
      alwaysShowRecipeDiscount,
      showDoubleCurrencyDash,
    } = this.props

    const dash = getFormattedCurrencyPlaceholder(showDoubleCurrencyDash)
    const showRecipeDiscount = parseFloat(recipeDiscountAmount) > 0 ? true : null
    const showExtrasTotalPrice = parseFloat(extrasTotalPrice) > 0 ? true : null
    const showSurchargeTotalPrice = surchargeCount > 0 && surchargeTotal !== '0.00'
    const deliveryLineStyleForCheckout = isDeliveryFree && !isDiscountSofteningFreeDelivery ? 'checkoutPrimary' : 'checkoutNormal'
    const deliveryLineStyleDefault = isDeliveryFree ? 'primary' : 'normal'
    const deliveryLineStyle = isReceiptInCheckout
      ? deliveryLineStyleForCheckout
      : deliveryLineStyleDefault

    const totalPrice = formatDashOrPrice(totalToPay, numRecipes, prices, dash, numRecipesOverBasketLimit)

    const formatDiscountAmount = (amount, alwaysShow) => {
      if (alwaysShow) {
        return amount ? formatPrice(amount) : <span className={css.dash}>£--</span>
      }

      return `-${formatPrice(amount)}`
    }

    return (
      <div className={classNames(css.row, { [css.rowInCheckout]: isReceiptInCheckout })}>
        {showTitleSection && (
          <div className={css.row}>
            <p className={css.titleSection}>Order Summary</p>
          </div>
        )}
        <ReceiptLine
          label={formatLabelPlural('Recipe', numRecipes)}
          lineStyle={isReceiptInCheckout ? 'checkoutNormal' : 'normal'}
          dataTesting="grossPrice"
        >
          {formatDashOrPrice(recipeTotalPrice, numRecipes, prices, dash, numRecipesOverBasketLimit)}
        </ReceiptLine>
        {(showRecipeDiscount || alwaysShowRecipeDiscount) && (
          <ReceiptLine
            label={formatRecipeDiscount(recipeDiscountPercent)}
            lineStyle={isReceiptInCheckout ? 'checkoutPrimary' : 'primary'}
            dataTesting="discountAmount"
            showLineAbove={isReceiptInCheckout || alwaysShowRecipeDiscount}
            isReceiptInCheckout={isReceiptInCheckout}
          >
            {formatDiscountAmount(recipeDiscountAmount, alwaysShowRecipeDiscount)}
          </ReceiptLine>
        )}
        {showSurchargeTotalPrice && (
          <ReceiptLine
            label="Surcharges and/or savings"
            showLineAbove
            lineStyle={isReceiptInCheckout ? 'checkoutNormal' : 'normal'}
            isReceiptInCheckout={isReceiptInCheckout}
          >
            {formatSurcharge(surchargeTotal)}
          </ReceiptLine>
        )}
        {showExtrasTotalPrice && (
          <ReceiptLine
            label="Sides and market items"
            showLineAbove
            lineStyle={isReceiptInCheckout ? 'checkoutNormal' : 'normal'}
            isReceiptInCheckout={isReceiptInCheckout}
          >
            {formatPrice(extrasTotalPrice)}
          </ReceiptLine>
        )}
        <ReceiptLine
          label="Delivery"
          showLineAbove
          lineStyle={deliveryLineStyle}
          isReceiptInCheckout={isReceiptInCheckout}
        >
          {formatDeliveryTotal(isDeliveryFree, deliveryTotalPrice, dash, numRecipesOverBasketLimit, isDiscountSofteningFreeDelivery, flatDeliveryFee, emphasiseFreeDelivery)}
        </ReceiptLine>
        <ReceiptLine
          label="Total"
          lineStyle={isReceiptInCheckout ? 'checkoutBold' : 'bold'}
          showLineAbove
          dataTesting="totalPrice"
          isReceiptInCheckout={isReceiptInCheckout}
        >
          {totalPrice}
        </ReceiptLine>
        {shippingAddress && (
          <ReceiptLine label="Delivery" showLineAbove>
            <DeliveryDetails address={shippingAddress} date={deliveryDate} slot={deliverySlot} />
          </ReceiptLine>
        )}
        {orderNumber && (
          <ReceiptLine
            label="Order number"
            showLineAbove
            lineStyle={isReceiptInCheckout ? 'checkoutNormal' : 'normal'}
          >
            {orderNumber}
          </ReceiptLine>
        )}
        {children}
      </div>
    )
  }
}

Receipt.propTypes = propTypes
Receipt.defaultProps = defaultProps
