import { createSelector } from 'reselect'

export const getPromoStore = (state: ReduxState) => state.promoStore

export const createGetPromoStoreEntry = (promoCode: string) =>
  createSelector(getPromoStore, (promoStore) => promoStore[promoCode])

export const getDiscountPercentFromPromoStoreEntry = (
  state: ReduxState,
  basketPromoCode: string,
) => {
  const promoStoreSelector = createGetPromoStoreEntry(basketPromoCode)

  return promoStoreSelector(state)?.details?.['discount-whole-order-percent']
}

interface ReduxState {
  promoCurrent: string
  promoStore: {
    [key in string]: Promo
  }
  promoAgeVerified: boolean
  promoModalVisible: boolean
}

/**
 * Part of application store that contains all the information used within signup domain.
 *
 * Note: do not nest ImmutableMap inside another ImmutableMap - `getIn` won't work; `getIn` will work fine with
 * nested objects instead.
 */
export interface Promo {
  code: string
  details: PromoDetails
  codeData: CodeData
  addGiftOrderRules: Array<any>
  hasAgeRestricted: boolean
  justApplied: boolean
  errorText?: string
}

interface PromoDetails {
  'discount-whole-order-amount': string
  'discount-whole-order-percent': string
  'apply-credit-to-originator': string
}

interface CodeData {
  code: string
  campaign: Campaign
}

interface Campaign {
  enabled: boolean
  landingDetails1: string
  landingDetails2: string
  landingDetails3: string
  isForExisting: boolean
  modalTitle: string
  boostCampaignId: number | null
  rawModalText: string
  expiryInterval: string
  landingUrl: string
  isBoost: boolean
  modalText: string
  id: number
  mediaId: number | null
}
