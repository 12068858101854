import { connect } from 'react-redux'

import userActions from 'actions/user'
import { getUserOrders } from 'selectors/user'

import { BuildYourNextBox } from './BuildYourNextBox'

const mapSateToProps = (state: any) => ({
  orders: getUserOrders(state).toJS(),
})

export const BuildYourNextBoxContainer = connect(mapSateToProps, {
  userLoadOrders: userActions.userLoadOrders,
})(BuildYourNextBox)
