import React from 'react'

import {
  AlignItems,
  Body2,
  Box,
  Color,
  Display,
  Icon,
  IconVariant,
  JustifyContent,
  withCitrusTheme,
} from '@gousto-internal/citrus-react'
import { browserHistory } from 'react-router'

import { client } from 'config/routes'

const onSpaceOrEnter =
  (callback: (event: React.KeyboardEvent) => void) => (event: React.KeyboardEvent) => {
    if (event.code === 'Space' || event.code === 'Enter') {
      event.preventDefault()
      callback(event)
    }
  }

const LinkButton = withCitrusTheme(Box, () => ({
  cursor: 'pointer',
}))

export const BackButton = () => (
  <LinkButton
    aria-label="Delivery"
    data-testid="back-to-delivery-details-button"
    display={Display.Flex}
    alignItems={AlignItems.Center}
    justifyContent={JustifyContent.Center}
    onClick={() => browserHistory.push(client.deliveryDetails)}
    onKeyDown={onSpaceOrEnter(() => browserHistory.push(client.deliveryDetails))}
    role="button"
    tabIndex={0}
  >
    <Icon name="arrow_back" variant={IconVariant.Actionable} />
    <Body2 color={Color.Secondary_400}>Delivery</Body2>
  </LinkButton>
)
