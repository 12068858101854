import { push, replace } from 'react-router-redux'

import { isServer } from '@library/environment'

import { canUseWindow } from 'utils/browserEnvironment'
import * as windowUtils from 'utils/window'

import { actionTypes } from './actionTypes'

export const redirect = (url, clearCookies) => {
  let action

  if (canUseWindow()) {
    action = push(url)
  } else if (isServer()) {
    action = {
      type: actionTypes.SERVER_REDIRECT,
      url,
      clearCookies,
    }
  } else {
    windowUtils.redirect(url)

    action = {
      type: actionTypes.VOID,
    }
  }

  return action
}

export default {
  redirect,
  replace: (url) => {
    let action

    if (canUseWindow()) {
      action = replace(url)
    } else if (isServer()) {
      action = {
        type: actionTypes.SERVER_REPLACE,
        url,
      }
    } else {
      windowUtils.replace(url)

      action = {
        type: actionTypes.VOID,
      }
    }

    return action
  },
}
